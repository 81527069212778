import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { ScheduleComplianceApi } from './schedule-compliance.api';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { ScheduleComplianceAction } from './schedule-compliance.action';
import { ComplianceRequest } from './schedule-compliance.model';
import { FeatureService } from '../../../startup/feature.service';
import { DateFormatType, Features } from '../../../enums';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { undo } from '../../shared/undo/undoAction';
import { getAccount, getAccountSubscription } from '../../account/account.service';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { format, parseDate } from '../../../shared/date.helper';
import { addMonths, isAfter, isBefore, startOfDay } from 'date-fns';

@Injectable()
export class ScheduleComplianceService {
  private fetchCompliances$ = new BehaviorSubject<ComplianceRequest>(null);

  constructor(
    private api: ScheduleComplianceApi,
    private store: Store<AppState>,
    private featureService: FeatureService
  ) {}

  public complianceListener() {
    return this.fetchCompliances$.pipe(
      filter((complianceRequest) => !!complianceRequest),
      filter(() => this.featureService.isFeatureActivated(Features.WIDGETBRAIN)),
      withLatestFrom(this.hasValidPermissions(), this.isAtLeastPremium(), this.isValidAccount()),
      filter(
        ([complianceRequest, hasValidPermissions, isAtLeastPremium, hasValidCountry]: [
          ComplianceRequest,
          boolean,
          boolean,
          boolean
        ]) => hasValidPermissions && isAtLeastPremium && hasValidCountry
      ),
      map(([complianceRequest]) => ({
          ...complianceRequest,
        })),
      mergeMap((complianceRequest: ComplianceRequest) => this.getScheduleCompliance(complianceRequest))
    );
  }

  private hasValidPermissions() {
    const check: PermissionCheck = {
      permissions: [
        'Create roster',
        'Create own roster',
        'Edit roster',
        'Edit own roster',
        'Delete roster',
        'Delete own roster',
      ],
      userId: 'me',
      departments: 'any',
    };
    return this.store.select(getPermissionState).pipe(
      map((permissionState) => hasPermission(check, permissionState)),
      filter((hasPermission) => hasPermission)
    );
  }

  private isAtLeastPremium() {
    return this.store.select(getAccountSubscription).pipe(
      map((subscription) => hasAtleastSubscriptionPlan(PlanType.PREMIUM, subscription)),
      filter((isAtLeastPremium) => isAtLeastPremium)
    );
  }

  private isValidAccount() {
    return this.store.select(getAccount).pipe(
      map((account) => account?.country === 'NL' && account?.schedule_compliance_check),
      filter((validAccount) => validAccount)
    );
  }

  public fetchCompliances(complianceRequest: ComplianceRequest) {
    const updatedDate = this.getValidatedDate(
      parseDate(complianceRequest.minDate),
      parseDate(complianceRequest.maxDate)
    );

    if (!updatedDate) {
      this.store.dispatch(ScheduleComplianceAction.reset());
      return;
    }

    this.fetchCompliances$.next({
      ...complianceRequest,
      minDate: updatedDate.minDate,
      maxDate: updatedDate.maxDate,
    });
  }

  private getValidatedDate(minDate: Date, maxDate: Date) {
    const today = startOfDay(new Date());
    const futureConstraint = addMonths(today, 3);
    if (isBefore(startOfDay(maxDate), today)) {
      return null;
    }

    if (isAfter(startOfDay(minDate), futureConstraint)) {
      return null;
    }

    let updatedMinDate = minDate;
    if (isBefore(minDate, today)) {
      updatedMinDate = today;
    }

    let updatedMaxDate = maxDate;
    if (isAfter(maxDate, futureConstraint)) {
      updatedMaxDate = futureConstraint;
    }

    return {
      minDate: format(updatedMinDate, DateFormatType.DEFAULT),
      maxDate: format(updatedMaxDate, DateFormatType.DEFAULT),
    };
  }

  public updateCompliances(employeeIds: string[]) {
    if (employeeIds?.length === 0) {
      return;
    }

    const currentDate = this.fetchCompliances$.getValue();

    if (!currentDate) {
      return;
    }

    const updatedDate = this.getValidatedDate(parseDate(currentDate.minDate), parseDate(currentDate.maxDate));
    if (!updatedDate) {
      return;
    }

    const uniqueEmployeeIds = new Set<string>(employeeIds);
    this.fetchCompliances$.next({
      minDate: updatedDate.minDate,
      maxDate: updatedDate.maxDate,
      employeeIds: [...uniqueEmployeeIds],
      update: true,
      realtime: true,
    });
  }

  private getScheduleCompliance(complianceRequest: ComplianceRequest) {
    const timestamp = new Date().getTime();
    const action = complianceRequest.update
      ? ScheduleComplianceAction.update(complianceRequest.employeeIds, timestamp)
      : ScheduleComplianceAction.load();

    return this.api.getScheduleCompliance(complianceRequest, action).pipe(
      tap((data) => {
        const successAction = complianceRequest.update
          ? ScheduleComplianceAction.updateSuccess(data, timestamp)
          : ScheduleComplianceAction.loadSuccess(data);
        this.store.dispatch(successAction);
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));
        const failAction = complianceRequest.update
          ? ScheduleComplianceAction.updateFailed(response)
          : ScheduleComplianceAction.loadFailed(response);
        this.store.dispatch(failAction);
        return of(response);
      })
    );
  }

  public destroy() {
    this.fetchCompliances$.next(null);
  }
}
