import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { RequiredShiftsFulfillmentRequest } from './required-shift-fulfillment.model';

@Injectable()
export class RequiredShiftFulfillmentApi {
  private endpoint = 'planning/required_shifts/fulfillment/';

  public constructor(private gateway: ApiGateway) {}

  public getRequiredShiftFulfillment(
    requestData: RequiredShiftsFulfillmentRequest,
    dispatchStart?: Action,
  ): Observable<any> {
    return this.gateway.get(
      this.endpoint,
      {
        params: objectToSearchParams(requestData, ArrayStrategy.COMMA_SEPARATED),
      },
      dispatchStart,
    );
  }
}
