import { requiredShiftFulfillmentAdapter, RequiredShiftFulfillmentState } from './required-shift-fulfillment.state';
import { AppState } from '../../index';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { RequiredShiftFulfillmentModel } from './required-shift-fulfillment.model';

export const getRequiredShiftFulfillmentState = (appState: AppState): RequiredShiftFulfillmentState =>
  appState.orm.requiredShiftFulfillment;

export const { selectAll, selectEntities, selectIds, selectTotal } = requiredShiftFulfillmentAdapter.getSelectors(
  getRequiredShiftFulfillmentState
);

export const getAllRequiredShiftFulfillment = createSelector(
  selectEntities,
  (fulfillmentEntities: Dictionary<RequiredShiftFulfillmentModel>): Dictionary<RequiredShiftFulfillmentModel> => fulfillmentEntities
);

export const getRequiredShiftFulfillment = (occurrenceId: string) =>
  createSelector(
    selectEntities,
    (fulfillmentEntities: Dictionary<RequiredShiftFulfillmentModel>): RequiredShiftFulfillmentModel => fulfillmentEntities[occurrenceId]
  );
