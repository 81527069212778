import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WeatherForecast } from './weather.model';
import { sortByAscendingDate } from '../../../shared/sort.helper';

export interface WeatherState extends EntityState<WeatherForecast> {
  isLoading?: boolean;
  error?: any;
}

export const weatherAdapter: EntityAdapter<WeatherForecast> = createEntityAdapter<WeatherForecast>({
  selectId: (weather) => weather.id,
  sortComparer: sortByAscendingDate,
});

export const initialState: WeatherState = weatherAdapter.getInitialState({
  isLoading: false,
  error: null,
});
