import { Dictionary } from '@ngrx/entity';
import { omit } from 'lodash-es';
import every from 'lodash-es/every';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import keys from 'lodash-es/keys';
import pickBy from 'lodash-es/pickBy';
import some from 'lodash-es/some';
import { createSelector } from 'reselect';

import { TeamScheduleData } from '../../../../app/reducers/store/teamdata/team-data.n.model';
import { AccountModel } from '../../../reducers/account/account.model';
import { getAccount } from '../../../reducers/account/account.service';
import { PermissionState } from '../../../reducers/auth/auth.model';
import { absenceInPeriod } from '../../../reducers/orm/absence/absence.helper';
import { AbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { sumPeriodAbsence } from '../../../reducers/orm/absence/absence.service';
import { AvailabilityModel } from '../../../reducers/orm/availability/availability.model';
import { sumContractInfo } from '../../../reducers/orm/contract/contract.service';
import { EmployeeWithContractInfo } from '../../../reducers/orm/employee/employee.model';
import { ScheduleModel } from '../../../reducers/orm/schedule/schedule.model';
import { sumSchedules } from '../../../reducers/orm/schedule/schedule.service';
import { TeamModel } from '../../../reducers/orm/team/team.model';
import { getSelectedSchedulePeriod } from '../../../reducers/page-filters/page-filters.helper';
import {
  EmployeeContractTypeFilter,
  ScheduleFilterPeriod,
  ScheduleFilterShowOptions,
  ScheduleFilterState,
} from '../../../reducers/page-filters/page-filters.model';
import { getScheduleFilters } from '../../../reducers/page-filters/page-filters.service';
import { dayList, periodFilter } from '../../../shared/date.helper';
import { totalAccumulator } from '../../../shared/total.helper';
import { hasPermission } from './../../../reducers/auth/permission.helper';
import { filterAndSumAbsenteeDays } from './../../../reducers/orm/absence/absence.service';
import { availabilityHasSchedule } from './schedule-conflict.helper';
import {
  getAbsenceForEmployeeSchedule,
  getAvailabilityForSchedule,
  getEmployeesForSchedule,
  getSelectedScheduledShifts,
} from './schedule-helper.service';
import { EmployeeWithScheduleTotals, TimeLineTeam } from './schedule.interfaces';

/**
 * Get All employees that should be added to the team
 * @param EmployeeWithScheduleTotals[] employees
 * @param {string} teamId
 * @returns {EmployeeWithScheduleTotals[]}
 */
const determineEmployeesInTeam = (employees: EmployeeWithScheduleTotals[], teamId: string) => {
  const employeesInTeam = employees.filter((employee) => employee.Team.indexOf(teamId) !== -1);
  const inTeamIds = employeesInTeam.map((employee) => employee.id);

  //get all employees that have a shift for the current team but are not part of it
  const loanedEmployees = employees
    .filter((employee) => {
      //remove employees that already are in the team
      if (inTeamIds.indexOf(employee.id) !== -1) {
        return false;
      }

      return some(employee.schedules, (schedule) => schedule.team_id === teamId);
    })
    // add loaned status to employee
    .map((employee) => ({
      ...employee,
      loaned: true,
    }));

  return [...employeesInTeam, ...loanedEmployees];
};

const applyShiftFilter = (shiftIds: string[], teamId: string, employees: EmployeeWithScheduleTotals[]) => {
  if (shiftIds.length === 0) {
    return employees;
  }

  return employees.map((employee) => {
    const filteredSchedules = employee.schedules.filter((schedule) => {
      if (schedule.team_id !== teamId) {
        return true;
      }

      return shiftIds.includes(schedule.shift_id);
    });

    return {
      ...employee,
      schedules: filteredSchedules,
    };
  });
};

const applyShowOptionsFilter = (
  filter: ScheduleFilterShowOptions,
  teamId: string,
  employees: EmployeeWithScheduleTotals[],
) => {
  // Filter loaned shifts
  if (!filter.loanedShifts) {
    employees = employees.map((employee) => {
      const filteredSchedules = employee.schedules.filter((schedule) => schedule.team_id === teamId);
      return {
        ...employee,
        schedules: filteredSchedules,
      };
    });
  }

  // Remove employees without data be it schedules, absences and availability
  if (!filter.includeEmployeesWithoutData) {
    employees = employees.filter(
      (employee) => !every([employee.schedules, employee.absences, employee.availabilities], isEmpty),
    );
  }

  return employees;
};

const applyContractFilter = (
  filter: EmployeeContractTypeFilter,
  period: ScheduleFilterPeriod,
  employees: EmployeeWithScheduleTotals[],
) => {
  const days = dayList(period.minDate, period.maxDate);

  const activeContracts = pickBy(filter, (value) => value);
  const activeContractsKeys = keys(activeContracts);

  return employees.filter((employee) => {
    // If no contract info is known assume a noContract.
    if (!employee.contractInfoPerDay) {
      return filter.noContract;
    }

    // Include the employee if one of the days in the period he has a visible contract type.
    return days.some((day) => {
      const contractInfo = employee.contractInfoPerDay[day];

      if (contractInfo) {
        return activeContractsKeys.includes(contractInfo.contractTypeId);
      } else {
        return filter.noContract;
      }
    });
  });
};

export const employeeContractFilter = (contractFilters) => (employee) => {
  const activeContracts = pickBy(contractFilters, (value) => value);
  const activeContractsKeys = keys(activeContracts);

  if (!employee.contractInfoPerDay) {
    return contractFilters.noContract;
  }

  const contractDays = Object.values(employee.contractInfoPerDay);

  return contractDays.some((contractDay: any) => {
    if (contractDay) {
      return activeContractsKeys.includes(contractDay.contractTypeId);
    } else {
      return contractFilters.noContract;
    }
  });
};

const applyFilters = (
  filters: ScheduleFilterState,
  team: TeamModel,
  employees: EmployeeWithScheduleTotals[],
  period: ScheduleFilterPeriod,
) => {
  const { showOptions, contract } = filters;

  const shiftFilters = get(filters, ['department', team.department_id, 'shifts'], []);

  let filteredEmployees = applyShiftFilter(shiftFilters, team.id, employees);

  filteredEmployees = applyShowOptionsFilter(showOptions, team.id, filteredEmployees);

  if (!isEmpty(contract)) {
    filteredEmployees = applyContractFilter(contract, period, filteredEmployees);
  }

  return filteredEmployees;
};

export const calculatePlannedHours = (
  period,
  minDate: string,
  maxDate: string,
  schedules: ScheduleModel[],
  absence: AbsenceModel[],
) => {
  const periodFilterFn = periodFilter(minDate, maxDate);

  if (period.periodType === 'day') {
    schedules = schedules.filter(periodFilterFn);
    absence = absence.filter(absenceInPeriod(minDate, maxDate));
  }

  const totalScheduled = sumSchedules(schedules);

  const absenceWithSum = absence.map((absenceRow) => filterAndSumAbsenteeDays(absenceRow, periodFilterFn));
  const totalAbsence = sumPeriodAbsence(absenceWithSum);

  return totalAccumulator(totalAbsence, totalScheduled);
};

export const getScheduledEmployeesWithTotals = createSelector(
  [
    getSelectedSchedulePeriod,
    (state) => getSelectedScheduledShifts(state),
    (state) => getEmployeesForSchedule(state),
    (state) => getAbsenceForEmployeeSchedule(state),
    (state) => getAvailabilityForSchedule(state),
    getScheduleFilters,
    getAccount,
  ],
  (
    period,
    schedules,
    employees: EmployeeWithContractInfo[],
    absence,
    availabilities,
    filters: ScheduleFilterState,
    account: AccountModel,
  ): EmployeeWithScheduleTotals[] => {
    const minDate = period.periodType !== 'day' ? period.minDate : period.selectedDate;
    const maxDate = period.periodType !== 'day' ? period.maxDate : period.selectedDate;

    const schedulesPerEmployee = groupBy(schedules, 'user_id');
    const absencePerEmployee = groupBy(absence, 'user_id');
    const availabilityPerEmployee = groupBy(availabilities, 'user_id');

    return employees.map((employee) => {
      let employeeSchedules: ScheduleModel[] = schedulesPerEmployee[employee.id] || [];
      const employeeAbsence = absencePerEmployee[employee.id] || [];

      if (account.coc_in_schedule) {
        employeeSchedules = employeeSchedules.map((schedule) => ({ ...schedule, salary: schedule.coc }));
      }

      let employeeAvailability: AvailabilityModel[] = availabilityPerEmployee[employee.id] || [];

      employeeSchedules = employeeSchedules.map((schedule) => ({
        ...schedule,
      }));

      employeeAvailability = employeeAvailability.map((availability) => ({
        ...availability,
        hasSchedule: availabilityHasSchedule(availability, employeeSchedules),
      }));

      const plannedHours = calculatePlannedHours(period, minDate, maxDate, employeeSchedules, employeeAbsence);
      const contractHours = sumContractInfo(employee.contractInfoPerDay);
      return {
        ...employee,
        plannedHours,
        contractHours,
        schedules: filters.showTypes.scheduledShifts ? employeeSchedules : [],
        absences: filters.showTypes.absence ? employeeAbsence : [],
        availabilities: filters.showTypes.availability ? employeeAvailability : [],
      };
    });
  },
);

export const mapEmployeesForTeam = (
  filters: ScheduleFilterState,
  team: TeamModel,
  employees: EmployeeWithScheduleTotals[],
  period: ScheduleFilterPeriod,
  permissionState: PermissionState,
): EmployeeWithScheduleTotals[] => {
  const employeesForTeam = determineEmployeesInTeam(employees, team.id);
  const mergedEmployees = applyFilters(filters, team, employeesForTeam, period);

  return mergedEmployees.map((employee) => {
    const canCreateSchedule = hasPermission(
      {
        permissions: ['Create roster', 'Create own roster'],
        userId: employee.id,
        departments: team.department_id,
      },
      permissionState,
    );

    const canEditSchedule = hasPermission(
      {
        permissions: ['Edit roster', 'Edit own roster'],
        userId: employee.id,
        departments: team.department_id,
      },
      permissionState,
    );

    const permissions = { schedule: { create: canCreateSchedule, edit: canEditSchedule } };

    return {
      ...employee,
      permissions,
    };
  });
};

export const filterOutEmptyEmployeeTeamsById = (
  teamScheduleData: Dictionary<TeamScheduleData>,
  viewType: 'team' | 'employee',
) => {
  const teamIdsToRemove = [];

  Object.values(teamScheduleData).forEach((teamSchedule) => {
    if (
      (viewType === 'employee' && teamSchedule.hasNoData && !teamSchedule.employees.length) ||
      (viewType === 'team' && teamSchedule.hasNoData)
    ) {
      teamIdsToRemove.push(teamSchedule.id);
    }
  });

  return omit(teamScheduleData, teamIdsToRemove);
};

export const getDepartmentPermissions = (
  departmentId: string,
  permissionState: PermissionState,
  teams: TimeLineTeam[],
) => {
  const canCreateEvent = hasPermission(
    {
      permissions: ['Create event'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canEditEvent = hasPermission(
    {
      permissions: ['Edit event'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canCreateOpenShift = hasPermission(
    {
      permissions: ['Create roster'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canCreateRequiredShift = hasPermission(
    {
      permissions: ['Create required shift'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canEditOpenShift = hasPermission(
    {
      permissions: ['Edit roster'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canEditRequiredShift = hasPermission(
    {
      permissions: ['Edit required shift'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canCreateSchedule = some(teams, (team) => team.permissions.schedule.create);
  const canEditSchedule = some(teams, (team) => team.permissions.schedule.edit);

  const canViewTeamNote = hasPermission(
    {
      permissions: ['View team notes', 'Write team notes'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canViewForecast = hasPermission(
    {
      permissions: ['View forecast', 'Edit forecast'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  const canViewBudget = hasPermission(
    {
      permissions: ['View budget', 'Edit budget'],
      userId: 'me',
      departments: departmentId,
    },
    permissionState,
  );

  return {
    event: {
      create: canCreateEvent,
      edit: canEditEvent,
    },
    openShift: {
      create: canCreateOpenShift,
      edit: canEditOpenShift,
    },
    requiredShift: {
      create: canCreateRequiredShift,
      edit: canEditRequiredShift,
    },
    schedule: {
      create: canCreateSchedule,
      edit: canEditSchedule,
    },
    teamNotes: {
      view: canViewTeamNote,
    },
    forecast: {
      view: canViewForecast,
    },
    budget: {
      view: canViewBudget,
    },
  };
};
