import { UnsafeAction as Action } from '../../interfaces';
import { WeatherLoadRequest } from './weather.model';

const ACTION_PREFIX = '[|Weather]';

export const weatherActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,
};

export class WeatherAction {
  static load(requestData): Action {
    return {
      type: weatherActionType.LOAD,
    };
  }

  static loadSuccess(weather, requestData: WeatherLoadRequest): Action {
    return {
      type: weatherActionType.LOAD_SUCCESS,
      payload: weather,
      requestData,
    };
  }

  static loadFailed(err): Action {
    return {
      type: weatherActionType.LOAD_FAILED,
      payload: err,
    };
  }
}
