import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ScheduleConflictStoreModel } from './schedule-conflict.model';

export interface ScheduleConflictState extends EntityState<ScheduleConflictStoreModel> {
  isLoading?: boolean;
  error?: any;
}

export const scheduleConflictModelEntityAdapter: EntityAdapter<ScheduleConflictStoreModel> = createEntityAdapter<
  ScheduleConflictStoreModel
>();

export const initialState: ScheduleConflictState = scheduleConflictModelEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
