import { AppState } from '../../index';
import { scheduleConflictModelEntityAdapter, ScheduleConflictState } from './schedule-conflict.state';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { ScheduleConflictModel, ScheduleConflictStoreModel } from './schedule-conflict.model';
import groupBy from 'lodash-es/groupBy';

export const getScheduleConflictState = (appState: AppState): ScheduleConflictState => appState.orm.scheduleConflicts;

export const { selectAll, selectEntities, selectIds, selectTotal } = scheduleConflictModelEntityAdapter.getSelectors(
  getScheduleConflictState
);

export const isConflictsLoading = createSelector(getScheduleConflictState, (conflictState: ScheduleConflictState) => conflictState.isLoading);

export const getScheduleConflictEntity = (employeeId: string) =>
  createSelector(selectEntities, (scheduleConflict: Dictionary<ScheduleConflictStoreModel>) => scheduleConflict[employeeId]);

export const getScheduleConflictsForStatusBar = createSelector(
  selectAll,
  (scheduleConflict: ScheduleConflictStoreModel[]) => scheduleConflict.reduce((acc, current) => ({ ...acc, ...current.conflicts }), {})
);

export const getScheduleConflictEntities = createSelector(
  selectEntities,
  (scheduleConflict: Dictionary<ScheduleConflictStoreModel>) => scheduleConflict
);

export const getScheduleConflict = (employeeId: string, occurrenceId: string) =>
  createSelector(
    getScheduleConflictEntity(employeeId),
    (scheduleConflictStoreModel): Dictionary<ScheduleConflictModel[]> => {
      const conflicts = scheduleConflictStoreModel?.conflicts;
      if (!conflicts || !conflicts[occurrenceId]?.length) {
        return null;
      }

      return groupBy(conflicts[occurrenceId], 'topic');
    }
  );
