import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

import { FeatureService } from '../../../startup/feature.service';
import { AppState } from '../../index';
import { WeatherAction } from './weather.action';
import { WeatherApi } from './weather.api';
import { WeatherLoadRequest } from './weather.model';

@Injectable()
export class WeatherService {
  public constructor(
    private store: Store<AppState>,
    private api: WeatherApi,
    private featureService: FeatureService,
  ) {}

  public load(weatherLoadRequest: WeatherLoadRequest, updateStore = true) {
    return this.api.load(weatherLoadRequest, WeatherAction.load(weatherLoadRequest)).pipe(
      first(),
      tap((response) => {
        if (updateStore) {
          this.store.dispatch(WeatherAction.loadSuccess(response, weatherLoadRequest));
        }
      }),
      catchError((error) => {
        this.store.dispatch(WeatherAction.loadFailed(error));
        return of(error);
      }),
    );
  }
}
