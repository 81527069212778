import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { objectToSearchParams } from '../../../api/api.helper';
import { ComplianceRequest } from './schedule-compliance.model';

@Injectable()
export class ScheduleComplianceApi {
  private endpoint = 'planning/compliance/violations/';

  constructor(private gateway: ApiGateway) {}

  public getScheduleCompliance(complianceRequest: ComplianceRequest, dispatchStart?: UnsafeAction) {
    const { minDate, maxDate, employeeIds, realtime } = complianceRequest;

    const requestData = {
      minDate,
      maxDate,
      realtime: realtime ? 1 : 0,
    };

    if (employeeIds?.length > 0) {
      requestData['employee_ids'] = employeeIds.join(',');
    }

    return this.gateway.get(this.endpoint, { params: objectToSearchParams(requestData) }, dispatchStart);
  }
}
