import { AppState } from '../../index';
import { scheduleComplianceModelEntityAdapter, ScheduleComplianceState } from './schedule-compliance.state';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { ComplianceViolation, ScheduleComplianceModel } from './schedule-compliance.model';
import { getScheduleEntities } from '../schedule/schedule.service';
import { getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { ScheduleModel } from '../schedule/schedule.model';

export const getScheduleComplianceState = (appState: AppState): ScheduleComplianceState =>
  appState.orm.scheduleCompliances;

export const { selectAll, selectEntities, selectIds, selectTotal } = scheduleComplianceModelEntityAdapter.getSelectors(
  getScheduleComplianceState
);

export const isViolationsLoading = createSelector(
  getScheduleComplianceState,
  (scheduleComplianceState: ScheduleComplianceState) => scheduleComplianceState.isLoading
);

export const getViolations = createSelector(selectAll, (scheduleCompliances: ScheduleComplianceModel[]) => scheduleCompliances.reduce((acc, current: ScheduleComplianceModel) => ({ ...acc, ...current.violations }), {}));

export const getScheduleViolationsCountForSelectedDepartments = createSelector(
  getViolations,
  getScheduleEntities,
  getSelectedDepartmentIds,
  (
    violations: Dictionary<ComplianceViolation>,
    scheduleEntities: Dictionary<ScheduleModel>,
    selectedDepartmentIds: string[]
  ) => Object.keys(violations ?? {}).filter((occurrenceId) => {
      const schedule = scheduleEntities[occurrenceId];
      if (!schedule) {
        return false;
      }

      return selectedDepartmentIds.includes(schedule?.department_id || schedule?.Department?.id);
    })?.length
);

export const getScheduleComplianceEntities = createSelector(
  selectEntities,
  (complianceEntities: Dictionary<ScheduleComplianceModel>) => complianceEntities
);

export const getScheduleComplianceEntity = (employeeId: string) =>
  createSelector(selectEntities, (complianceEntities) => complianceEntities[employeeId]);

export const getScheduleViolations = (userId: number, occurrenceId: string) =>
  createSelector(selectEntities, (scheduleCompliances: Dictionary<ScheduleComplianceModel>) => scheduleCompliances[userId]?.violations[occurrenceId]);
