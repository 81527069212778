import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ScheduleComplianceModel } from './schedule-compliance.model';

export interface ScheduleComplianceState extends EntityState<ScheduleComplianceModel> {
  isLoading?: boolean;
  error?: any;
}

export const scheduleComplianceModelEntityAdapter: EntityAdapter<ScheduleComplianceModel> = createEntityAdapter<
  ScheduleComplianceModel
>({
  selectId: (model) => model.userId,
});

export const initialState: ScheduleComplianceState = scheduleComplianceModelEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
