/* eslint-disable max-lines */
import { eachDayOfInterval, isValid } from 'date-fns';
import groupBy from 'lodash-es/groupBy';
import some from 'lodash-es/some';
import zipWith from 'lodash-es/zipWith';
import { createSelector } from 'reselect';

import { PermissionState } from '../../../../reducers/auth/auth.model';
import { getPermissionState } from '../../../../reducers/auth/permission.helper';
import { AbsenceModel, AbsenteeDay } from '../../../../reducers/orm/absence/absence.model';
import { absenceForDayWithPeriodSum, sumPeriodAbsence } from '../../../../reducers/orm/absence/absence.service';
import { EventModel } from '../../../../reducers/orm/event/event.model';
import { OpenShiftModel } from '../../../../reducers/orm/open-shift/open-shift.model';
import { sumOpenShifts } from '../../../../reducers/orm/open-shift/open-shift.selector';
import { RequiredShiftModel } from '../../../../reducers/orm/required-shift/required-shift.model';
import { sumRequiredShifts } from '../../../../reducers/orm/required-shift/required-shift.service';
import { ScheduleModel } from '../../../../reducers/orm/schedule/schedule.model';
import { sumSchedules } from '../../../../reducers/orm/schedule/schedule.service';
import { TeamDayModel } from '../../../../reducers/orm/team-day/team-day.model';
import { TeamModel } from '../../../../reducers/orm/team/team.model';
import { getWeatherForDepartmentPeriod } from '../../../../reducers/orm/weather/weather.selector';
import { getSelectedSchedulePeriod } from '../../../../reducers/page-filters/page-filters.helper';
import { ScheduleFilterPeriod, ScheduleFilterState } from '../../../../reducers/page-filters/page-filters.model';
import { getScheduleFilters } from '../../../../reducers/page-filters/page-filters.service';
import { convertArrayToObject } from '../../../../reducers/shared/entity.helper';
import { format, rangeList } from '../../../../shared/date.helper';
import { DateRange } from '../../../../shared/interfaces';
import { defaultTotal, totalAccumulator } from '../../../../shared/total.helper';
import { getPublishedForPeriod } from '../../shared/published-schedule.helper';
import {
  getEventsPerDepartmentForSchedule,
  getSelectedOpenShiftsPerTeam,
  getSelectedRequiredShiftsPerTeam,
  getTeamDaysForSchedule,
  getVisibleTeamsForDepartment,
  normalizePosition,
} from '../../shared/schedule-helper.service';
import {
  getDepartmentPermissions,
  getScheduledEmployeesWithTotals,
  mapEmployeesForTeam,
} from '../../shared/schedule-selector.helper';
import {
  DayWithoutTotal,
  EmployeeWithScheduleTotals,
  OpenShiftData,
  Positionable,
  PositionMapper,
  PublishedData,
  PublishedDepDays,
  RequiredShiftData,
  TimeLineDepartment,
  TimelineEventData,
  TimelinePeriod,
  TimeLineTeam,
  TotalData,
  TotalDay,
} from '../../shared/schedule.interfaces';

export const defaultTotalData: TotalData = {
  total: defaultTotal,
  absenceTotal: defaultTotal,
  scheduleTotal: defaultTotal,
  budget: defaultTotal,
  overBudget: {
    hours: false,
    pay: false,
  },
  forecast: {
    amount: 0,
    percentage: 0,
  },
};

export const getDayList = (period: DateRange): TotalDay[] => {
  const ranges = rangeList(period, 'day', { excludeEnd: false });

  return ranges.map(
    (dayPeriod: DateRange): TotalDay => ({
      date: format(dayPeriod.start, 'yyyy-MM-dd'),
      range: dayPeriod,
      total: defaultTotal,
      absenceTotal: defaultTotal,
      scheduleTotal: defaultTotal,
    }),
  );
};

export const getDayListWithoutTotals = (period: DateRange): DayWithoutTotal[] => {
  const ranges = rangeList(period, 'day', { excludeEnd: false });
  return ranges.map((dayPeriod: DateRange) => ({
    date: format(dayPeriod.start, 'yyyy-MM-dd'),
    range: dayPeriod,
  }));
};

const scheduleInTeam = (teamId) => (schedule: ScheduleModel) => schedule.team_id === teamId;

const determinePosition = <T extends Positionable>(positionedSchedules: T[], schedule: T): number => {
  const positions = positionedSchedules
    .filter((positionedSchedule) => schedule.date === positionedSchedule.date)
    .map((positionedSchedule) => positionedSchedule.position);

  if (!positions.length) {
    return 0;
  }

  //return first free position
  for (let index = 0; ++index; ) {
    if (positions.indexOf(index) === -1) {
      return index;
    }
  }
  return 0;
};

export const mapSchedulePositions: PositionMapper = <T extends Positionable>(
  shifts: T[],
  absencePosition?: number,
): T[] =>
  shifts.reduce((positionedSchedules: T[], shift: T) => {
    const position = determinePosition(positionedSchedules, shift);

    return [...positionedSchedules, Object.assign({}, shift, { position }, { absencePosition })];
  }, []);

export const mapAbsencePositions = (absences: AbsenceModel[]) => {
  const absencePosition = {};
  return absences.map((absence) => {
    const absenteeDays = Object.values(absence.AbsenteeDay);
    const absenteeDayWithPositions = absenteeDays.map((absenteeDay) => {
      if (!(absenteeDay.date in absencePosition)) {
        absencePosition[absenteeDay.date] = 0;
      } else {
        absencePosition[absenteeDay.date] = absencePosition[absenteeDay.date] + 1;
      }

      return {
        ...absenteeDay,
        position: absencePosition[absenteeDay.date],
      };
    });
    return {
      ...absence,
      AbsenteeDay: convertArrayToObject('date', absenteeDayWithPositions),
    };
  });
};

const mapEmployeeTeamTotal = (
  employee: EmployeeWithScheduleTotals,
  days: TotalDay[],
  team: TeamModel,
): EmployeeWithScheduleTotals => {
  const absencesWithPosition = mapAbsencePositions(employee.absences);
  const absencePositions = absencesWithPosition.map((absence) => {
    const absenteeDays = Object.values(absence.AbsenteeDay);
    return absenteeDays.map((absenteeDay: AbsenteeDay) => absenteeDay.position);
  });
  const maxAbsencePosition = normalizePosition(Math.max(...absencePositions.flat(2)));
  const schedulesWithPosition = mapSchedulePositions(employee.schedules, maxAbsencePosition);

  const shiftsPerDay = groupBy(employee.schedules, 'date');
  const teamFilter = scheduleInTeam(team.id);

  const employeeDays: TotalDay[] = days.map((day): TotalDay => {
    const contract = employee.contractInfoPerDay ? employee.contractInfoPerDay[day.date] : void 0;

    const schedules = shiftsPerDay[day.date] || [];

    const schedulesInTeam = schedules.filter(teamFilter);

    const scheduleTotal = sumSchedules(schedulesInTeam);

    let absenceTotal = defaultTotal;

    // Add absence costs and hours to total
    if (contract && team.department_id === contract.departmentId && !employee.loaned) {
      const absences = absenceForDayWithPeriodSum(day.date, employee.absences);

      // add absence calculation to total;
      absenceTotal = sumPeriodAbsence(absences);
    }

    const total = totalAccumulator(scheduleTotal, absenceTotal);

    return {
      ...day,
      total,
      absenceTotal,
      scheduleTotal,
    };
  });

  const teamTotal = employeeDays.map((employeeDay) => employeeDay.total).reduce(totalAccumulator, defaultTotal);

  const absenceTotal = employeeDays.map((employee) => employee.absenceTotal).reduce(totalAccumulator, defaultTotal);

  const scheduleTotal = employeeDays.map((employee) => employee.scheduleTotal).reduce(totalAccumulator, defaultTotal);

  const positions = schedulesWithPosition.map((schedule) => schedule.position);
  const absencePosition = absencePositions.length === 0 ? 0 : maxAbsencePosition / 2 + 0.5;
  const normalizedPosition = normalizePosition(Math.max(...positions));
  const maxPosition = positions.length === 0 ? absencePosition : normalizedPosition + absencePosition;
  const schedulePosition = positions.length === 0 ? 0 : normalizedPosition;

  return {
    ...employee,
    absences: absencesWithPosition,
    schedules: schedulesWithPosition,
    totalsPerDay: employeeDays,
    teamTotal,
    absenceTotal,
    scheduleTotal,
    maxPosition,
    absencePosition,
    schedulePosition,
  };
};

const accumulateDayTotals = (acc: TotalDay, totalData: TotalDay): TotalDay => {
  const total = totalAccumulator(acc.total, totalData.total);

  const result = {
    ...acc,
    total: total,
  };

  if (typeof acc.budget !== 'undefined' || typeof totalData.budget !== 'undefined') {
    const budget = totalAccumulator(acc.budget || defaultTotal, totalData.budget || defaultTotal);
    result.budget = budget;
    result.overBudget = {
      hours: budget.hours !== 0 && total.hours > budget.hours,
      pay: budget.pay !== 0 && total.pay > budget.pay,
    };
  }

  if (typeof acc.forecast !== 'undefined' || typeof totalData.forecast !== 'undefined') {
    const forecast = acc.forecast || totalData.forecast;
    const forecastAmount = forecast.amount;
    result.forecast = {
      amount: forecastAmount,
      percentage: forecastAmount === 0 || total.pay === 0 ? 0 : (total.pay / forecastAmount) * 100,
    };
  }
  return result;
};

export const accumulateTotalData = (acc: TotalData, totalData: TotalData): TotalData => {
  const total = totalAccumulator(acc.total, totalData.total);

  const result = {
    ...acc,
    total,
  };

  if (typeof acc.budget !== 'undefined' || typeof totalData.budget !== 'undefined') {
    const budget = totalAccumulator(acc.budget || defaultTotal, totalData.budget || defaultTotal);
    result.budget = budget;
    result.overBudget = {
      hours: budget.hours !== 0 && total.hours > budget.hours,
      pay: budget.pay !== 0 && total.pay > budget.pay,
    };
  }

  if (typeof acc.published !== 'undefined' || typeof totalData.published !== 'undefined') {
    result.published =
      (typeof acc.published === 'undefined' || acc.published) &&
      (typeof totalData.published === 'undefined' || totalData.published);
  }

  if (typeof acc.canPublish !== 'undefined' || typeof totalData.canPublish !== 'undefined') {
    result.canPublish = acc.canPublish || totalData.canPublish;
    result.isSavingPublishedState = acc.isSavingPublishedState || totalData.isSavingPublishedState;
  }

  if (typeof acc.forecast !== 'undefined' || typeof totalData.forecast !== 'undefined') {
    const accForecast = acc.forecast ? acc.forecast.amount : 0;
    const addForecast = totalData.forecast ? totalData.forecast.amount : 0;

    const forecastAmount = accForecast + addForecast;

    result.forecast = {
      amount: forecastAmount,
      percentage: forecastAmount === 0 || total.pay === 0 ? 0 : (total.pay / forecastAmount) * 100,
    };
  }
  return result;
};

export const sumTotalsPerDay = (days: TotalDay[], shiftsPerDay: TotalDay[][]): TotalDay[] =>
  zipWith(days, ...shiftsPerDay, (defaultDayTotal, ...dayTotals) => {
    if (!dayTotals) {
      return defaultDayTotal;
    }

    const totalData = dayTotals.reduce(accumulateDayTotals, defaultDayTotal);

    return {
      ...defaultDayTotal,
      ...totalData,
    };
  });

const getOpenShiftData = (days: TotalDay[], openShifts: OpenShiftModel[]): OpenShiftData => {
  const openShiftsWithPosition = mapSchedulePositions(openShifts);
  const openShiftsPerDay = groupBy(openShifts, 'date');

  const totalsPerDay: TotalDay[] = days.map((day): TotalDay => {
    const shifts = openShiftsPerDay[day.date] || [];

    return {
      ...day,
      total: sumOpenShifts(shifts),
    };
  });

  const teamTotal = totalsPerDay.map((totalDay) => totalDay.total).reduce(totalAccumulator, defaultTotal);

  const positions = openShiftsWithPosition.map((schedule) => schedule.position);
  const maxPosition = positions.length === 0 ? 0 : Math.max(...positions);

  return {
    openShifts: openShiftsWithPosition,
    totalsPerDay,
    teamTotal,
    maxPosition: maxPosition,
  };
};

const getRequiredShiftData = (days: TotalDay[], requiredShifts: RequiredShiftModel[]): RequiredShiftData => {
  const requiredShiftsWithPosition = mapSchedulePositions(requiredShifts);
  const requiredShiftsPerDay = groupBy(requiredShifts, 'date');

  const totalsPerDay: TotalDay[] = days.map((day): TotalDay => {
    const shifts = requiredShiftsPerDay[day.date] || [];

    return {
      ...day,
      total: sumRequiredShifts(shifts),
    };
  });

  const teamTotal = totalsPerDay.map((totalDay) => totalDay.total).reduce(totalAccumulator, defaultTotal);

  const positions = requiredShiftsWithPosition.map((schedule) => schedule.position);
  const maxPosition = positions.length === 0 ? 0 : Math.max(...positions);

  return {
    requiredShifts: requiredShiftsWithPosition,
    totalsPerDay,
    teamTotal,
    maxPosition: maxPosition,
  };
};

export const getTimelineEventData = (events: EventModel[]): TimelineEventData => {
  const eventsWithPosition = mapSchedulePositions(events);
  const positions = eventsWithPosition.map((event) => event.position);
  const maxPosition = positions.length === 0 ? 0 : Math.max(...positions);

  return {
    events: eventsWithPosition,
    maxPosition,
  };
};

export const mapTeamForPeriod = (
  filters: ScheduleFilterState,
  days: TotalDay[],
  period: ScheduleFilterPeriod,
  team: TeamModel,
  employees: EmployeeWithScheduleTotals[],
  openShifts: OpenShiftModel[],
  requiredShifts: RequiredShiftModel[],
  publishedDataPerDay: { [day: string]: PublishedData },
  teamDays: TeamDayModel[],
  permissionState: PermissionState,
): TimeLineTeam => {
  const openShiftData = getOpenShiftData(days, openShifts);
  const requiredShiftData = getRequiredShiftData(days, requiredShifts);

  //all employees that belong in this team
  employees = mapEmployeesForTeam(filters, team, employees, period, permissionState);

  //calculate employeeTeamtotals
  const employeeWithTotalsPerDay = employees.map((employee) => mapEmployeeTeamTotal(employee, days, team));

  const totalsPerDayPerEmployee = employeeWithTotalsPerDay.map((employee) => employee.totalsPerDay);

  // sum employee totals per day
  const teamTotalsPerDay = days.map(mapTeamDataToTeamDay(publishedDataPerDay, teamDays));
  const totalsPerDay = sumTotalsPerDay(teamTotalsPerDay, [...totalsPerDayPerEmployee, openShiftData.totalsPerDay]);
  // sum dayTotals to TotalData
  const totalData = totalsPerDay.reduce(accumulateTotalData, defaultTotalData);

  totalData.absenceTotal = employeeWithTotalsPerDay
    .map((employee) => employee.absenceTotal)
    .reduce(totalAccumulator, defaultTotal);

  totalData.scheduleTotal = employeeWithTotalsPerDay
    .map((employee) => employee.scheduleTotal)
    .reduce(totalAccumulator, defaultTotal);

  const canCreateSchedule = some(employees, (employee) => employee.permissions.schedule.create);
  const canEditSchedule = some(employees, (employee) => employee.permissions.schedule.edit);

  return {
    ...team,
    employees: employeeWithTotalsPerDay,
    openShiftData,
    requiredShiftData,
    total: totalData,
    totalsPerDay,
    permissions: {
      schedule: {
        create: canCreateSchedule,
        edit: canEditSchedule,
      },
    },
  };
};

export const mapDataForPeriod = (days: TotalDay[], requiredShifts: RequiredShiftModel[]): RequiredShiftData =>
  getRequiredShiftData(days, requiredShifts);

const mapTeamDataToTeamDay =
  (publishedData: { [day: string]: PublishedData }, teamDays: TeamDayModel[]) =>
  (day: TotalDay): TotalDay => {
    const departmentLog: PublishedData = publishedData.hasOwnProperty(day.date) ? publishedData[day.date] : undefined;

    const teamDay = teamDays.find((teamDayInstance) => teamDayInstance.date === day.date);
    const budget = { ...defaultTotal };

    if (teamDay) {
      budget.hours = teamDay.budget_time ? parseFloat(teamDay.budget_time) : 0;
      budget.pay = teamDay.budget_cost ? parseFloat(teamDay.budget_cost) : 0;
    }

    return {
      ...day,
      teamDay,
      budget,
      overBudget: {
        hours: false,
        pay: false,
      },
      forecast: {
        amount: departmentLog ? departmentLog.expected_turnover : 0,
        percentage: 0,
      },
    };
  };

export const getDataForEmployeeSchedulePeriodPerDepartment = (departmentId: string) =>
  createSelector(
    getScheduleFilters,
    getSelectedSchedulePeriod,
    getScheduledEmployeesWithTotals,
    getVisibleTeamsForDepartment(departmentId),
    getPublishedForPeriod,
    getTeamDaysForSchedule(departmentId),
    getSelectedOpenShiftsPerTeam(departmentId),
    getSelectedRequiredShiftsPerTeam(departmentId),
    getEventsPerDepartmentForSchedule(departmentId),
    getPermissionState,
    getWeatherForDepartmentPeriod(departmentId),
    (
      filters: ScheduleFilterState,
      period: ScheduleFilterPeriod,
      scheduledEmployees: EmployeeWithScheduleTotals[],
      teams: TeamModel[],
      publishedDepDays: PublishedDepDays,
      teamDaysPerTeam,
      openShiftsPerTeam,
      requiredShiftsPerTeam,
      events: { [departmentId: string]: EventModel[] },
      permissionState: PermissionState,
      weatherForecasts,
    ): TimeLineDepartment => {
      if (period.periodType === 'day') {
        return;
      }
      let dayList = getDayList(period.range);

      const publishedDepartmentData = publishedDepDays[departmentId];

      const mappedTeams = teams.map((team) => {
        const teamOpenShifts = openShiftsPerTeam[team.id] || [];
        const teamRequiredShifts = requiredShiftsPerTeam[team.id] || [];
        const teamDays = teamDaysPerTeam[team.id] || [];
        return mapTeamForPeriod(
          filters,
          dayList,
          period,
          team,
          scheduledEmployees,
          teamOpenShifts,
          teamRequiredShifts,
          publishedDepartmentData,
          teamDays,
          permissionState,
        );
      });

      const departmentData = mapDataForPeriod(
        dayList,
        requiredShiftsPerTeam['null'] ? requiredShiftsPerTeam['null'] : [],
      );
      const totalsPerDayPerTeam = mappedTeams.map((team) => team.totalsPerDay);

      //add published status to dayList
      dayList = dayList.map((day) => {
        const publishedData = publishedDepartmentData[day.date];
        return {
          ...day,
          published: publishedData && publishedData.isPublished,
          canPublish: publishedData && publishedData.canPublish,
          isSavingPublishedState: publishedData && publishedData.saving,
        };
      });

      const totalsPerDay = sumTotalsPerDay(dayList, totalsPerDayPerTeam);
      const totalData = totalsPerDay.reduce(accumulateTotalData, defaultTotalData);
      const permissions = getDepartmentPermissions(departmentId, permissionState, mappedTeams);

      return {
        requiredShiftData: departmentData,
        teams: mappedTeams,
        totalsPerDay,
        total: totalData,
        eventsData: getTimelineEventData(events[departmentId] || []),
        weatherForecasts,
        permissions,
      };
    },
  );

export const getTimelinePeriod = createSelector(getSelectedSchedulePeriod, (period): TimelinePeriod => {
  const rangeType = 'day';
  const intervalCheck =
    period &&
    period.range &&
    isValid(period.range.start) &&
    isValid(period.range.end) &&
    period.range.end >= period.range.start;

  if (intervalCheck) {
    const days = eachDayOfInterval(period.range);

    return {
      rangeType,
      periodType: period.periodType,
      days,
      start: period.range.start,
      end: period.range.end,
    };
  }
});
