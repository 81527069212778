import { UnsafeAction as Action } from '../../interfaces';
import { RequiredShiftFulfillmentModel } from './required-shift-fulfillment.model';

const ACTION_PREFIX = '[RequiredShiftFulfillment]';

export const requiredShiftFulfillmentActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,
  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,
};

export class RequiredShiftFulfillmentAction {
  static load(): Action {
    return {
      type: requiredShiftFulfillmentActionType.LOAD,
    };
  }

  static loadSuccess(requiredShiftFulfillment: RequiredShiftFulfillmentModel[]) {
    return {
      type: requiredShiftFulfillmentActionType.LOAD_SUCCESS,
      payload: requiredShiftFulfillment,
    };
  }

  static loadFailed(err): Action {
    return {
      type: requiredShiftFulfillmentActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static update(timestamp: number): Action {
    return {
      type: requiredShiftFulfillmentActionType.UPDATE,
      timestamp,
    };
  }

  static updateSuccess(requiredShiftFulfillment: RequiredShiftFulfillmentModel[], timestamp: number) {
    return {
      type: requiredShiftFulfillmentActionType.UPDATE_SUCCESS,
      payload: requiredShiftFulfillment,
      timestamp,
    };
  }

  static updateFailed(err): Action {
    return {
      type: requiredShiftFulfillmentActionType.UPDATE_FAILED,
      payload: err,
    };
  }
}
