import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { WeatherSchema } from '../../shared/schemas';
import { WeatherLoadRequest } from './weather.model';

@Injectable()
export class WeatherApi {
  private endpoint = 'weather/';

  public constructor(private gateway: ApiGateway) {}

  public load(requestData: WeatherLoadRequest, dispatchStart?: Action): Observable<any> {
    return this.gateway
      .get(
        this.endpoint,
        {
          params: objectToSearchParams(requestData, ArrayStrategy.COMMA_SEPARATED),
        },
        dispatchStart,
      )
      .pipe(map((data) => normalize(data, arrayOf(WeatherSchema), { assignEntity: assignSchemaEntity })));
  }
}
