/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { DaylogsLoadRequest } from '@app/reducers/orm/daylog/daylog.api';
import { EventsLoadRequest } from '@app/reducers/orm/event/event.api';
import { TeamDaysLoadRequest } from '@app/reducers/orm/team-day/team-day.api';
import { WeatherLoadRequest } from '@app/reducers/orm/weather/weather.model';
import { FeatureService } from '@app/startup/feature.service';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { getAccountSubscription } from '@reducers/account/account.service';
import { PermissionState } from '@reducers/auth/auth.model';
import { getAuthenticatedUserId } from '@reducers/auth/auth.service';
import {
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  hasPermission,
  PermissionCheck,
} from '@reducers/auth/permission.helper';
import { AppState } from '@reducers/index';
import { AbsenceModel, EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import {
  absenceForDateRange,
  absenceForPeriod,
  AbsenceService,
  getAbsenceEnhanced,
} from '@reducers/orm/absence/absence.service';
import { AvailabilityModel } from '@reducers/orm/availability/availability.model';
import {
  availabilityForPeriod,
  AvailabilityService,
  getAvailabilities,
} from '@reducers/orm/availability/availability.service';
import { ContractInfoPerEmployeePerDay } from '@reducers/orm/contract/contract.model';
import { contractInfoPerEmployeePerDay, ContractService, getContracts } from '@reducers/orm/contract/contract.service';
import { DaylogService } from '@reducers/orm/daylog/daylog.service';
import { DepartmentModel } from '@reducers/orm/department/department.model';
import { getDepartmentEntities, getSelectedDepartments } from '@reducers/orm/department/department.service';
import { EmployeeModel, EmployeeWithContractInfo } from '@reducers/orm/employee/employee.model';
import {
  employeeActiveInPeriod,
  employeeInDepartment,
  getEmployeeEntities,
  getEmployees,
  modelEmployeeInList,
} from '@reducers/orm/employee/employee.service';
import { EventModel } from '@reducers/orm/event/event.model';
import { EventService, getEvents } from '@reducers/orm/event/event.service';
import { ExchangeService } from '@reducers/orm/exchange/exchange.service';
import { HolidayModel } from '@reducers/orm/holiday/holiday.model';
import { getHolidays } from '@reducers/orm/holiday/holiday.selectors';
import { getLocationEntities } from '@reducers/orm/location/location.service';
import { OpenShiftModel, OpenShiftsLoadRequest } from '@reducers/orm/open-shift/open-shift.model';
import {
  enhanceOpenShift,
  getEnhancedOpenShifts,
  getOpenShiftState,
  hasOpenShiftPermission,
} from '@reducers/orm/open-shift/open-shift.selector';
import { OpenShiftService } from '@reducers/orm/open-shift/open-shift.service';
import { RequiredShiftFulfillmentModel } from '@reducers/orm/required-shift-fulfillment/required-shift-fulfillment.model';
import { getAllRequiredShiftFulfillment } from '@reducers/orm/required-shift-fulfillment/required-shift-fulfillment.selector';
import { RequiredShiftFulfillmentService } from '@reducers/orm/required-shift-fulfillment/required-shift-fulfillment.service';
import { RequiredShiftModel, RequiredShiftsLoadRequest } from '@reducers/orm/required-shift/required-shift.model';
import {
  enhanceRequiredShift,
  getEnhancedRequiredShifts,
  getRequiredShifts,
  getRequiredShiftState,
  hasRequiredShiftPermission,
  RequiredShiftService,
} from '@reducers/orm/required-shift/required-shift.service';
import {
  getScheduleComplianceEntities,
  getScheduleViolationsCountForSelectedDepartments,
} from '@reducers/orm/schedule-compliance/schedule-compliance.selectors';
import { ScheduleComplianceService } from '@reducers/orm/schedule-compliance/schedule-compliance.service';
import {
  ScheduleConflictModel,
  ScheduleConflictStoreModel,
} from '@reducers/orm/schedule-conflict/schedule-conflict.model';
import {
  getScheduleConflictEntities,
  getScheduleConflictsForStatusBar,
} from '@reducers/orm/schedule-conflict/schedule-conflict.selectors';
import { ScheduleConflictService } from '@reducers/orm/schedule-conflict/schedule-conflict.service';
import { ScheduleModel, SchedulesLoadRequest } from '@reducers/orm/schedule/schedule.model';
import {
  enhanceSchedule,
  getEnhancedSchedules,
  getSchedules,
  hasSchedulePermission,
  ScheduleService,
} from '@reducers/orm/schedule/schedule.service';
import { getShiftEntities } from '@reducers/orm/shift/shift.service';
import { TeamDayModel } from '@reducers/orm/team-day/team-day.model';
import { getTeamDays, TeamDayService } from '@reducers/orm/team-day/team-day.service';
import { TeamModel } from '@reducers/orm/team/team.model';
import {
  getActiveTeamsGroupedByDepartment,
  getTeamEntities,
  getVisibleTeams,
  getVisibleTeamsGroupedByDepartment,
} from '@reducers/orm/team/team.service';
import { WeatherService } from '@reducers/orm/weather/weather.service';
import { getSelectedSchedulePeriod } from '@reducers/page-filters/page-filters.helper';
import { ScheduleFilterPeriod, ScheduleFilterState } from '@reducers/page-filters/page-filters.model';
import { getScheduleFilters } from '@reducers/page-filters/page-filters.service';
import { isScheduleViewOpen } from '@reducers/page-params-state/page-params.service';
import { getSelectedDepartmentIds } from '@reducers/selected-departments/selected-departments.service';
import { combinedFilter } from '@reducers/shared/entity.helper';
import { ScheduleStatusModel } from '@reducers/store/departmentdata/department-data-n.selector';
import { entityInPeriod } from '@reducers/store/schedule-store.helpers';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import { createSelector } from 'reselect';
import { forkJoin, forkJoin as observableForkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { periodDateRangeFilter, periodFilter } from '../../../shared/date.helper';
import { lazySelect } from '../../../shared/lazy-select.observable';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType, SubscriptionModel } from '../../+reports/shared/subscriptions/subscription.model';
import { getVisibleTeamsGroupedByDepartmentForTimesheet } from '../../+timesheet/shared/timesheet-helper.service';
import { employeeSkillFilter } from './../../+employees/employee-list.helper';

@Injectable()
export class ScheduleHelperService {
  public constructor(
    private store: Store<AppState>,
    private scheduleService: ScheduleService,
    private openShiftService: OpenShiftService,
    private requiredShiftService: RequiredShiftService,
    private requiredShiftFulfillmentService: RequiredShiftFulfillmentService,
    private absenceService: AbsenceService,
    private availabilityService: AvailabilityService,
    private daylogService: DaylogService,
    private contractService: ContractService,
    private teamDayService: TeamDayService,
    private eventService: EventService,
    private weatherService: WeatherService,
    private complianceService: ScheduleComplianceService,
    private conflictService: ScheduleConflictService,
    private exchangeService: ExchangeService,
    private featureService: FeatureService,
  ) {}

  public subscribeToComplianceService() {
    return this.complianceService.complianceListener();
  }

  public unsubscribe() {
    this.complianceService.destroy();
  }

  public loadCompliances(minDate: string, maxDate: string) {
    this.complianceService.fetchCompliances({ minDate, maxDate });
  }

  public updateSchedule(period: { userId?: string; minDate: string; maxDate: string }) {
    return this.store.select(isScheduleViewOpen).pipe(
      switchMap((isOpen) => {
        if (!isOpen) {
          return of(null);
        }

        return forkJoin([
          this.loadOpenShifts(omit(period, 'userId'), true),
          this.loadSchedules(period, true),
          this.exchangeService.loadAllPending(),
        ]);
      }),
    );
  }

  public loadDataObs(minDate: string, maxDate: string, selectedDepartmentIds?: string[], loanedShiftsFilter?: boolean) {
    const period = {
      minDate,
      maxDate,
    };

    const periodAndDepartments = {
      ...period,
      departmentIds: selectedDepartmentIds,
    };

    return observableForkJoin([
      this.requiredShiftFulfillmentService.getRequiredShiftFulfillment(periodAndDepartments),
      this.conflictService.getAllConflicts(periodAndDepartments),
      this.loadSchedules(period, false, selectedDepartmentIds, loanedShiftsFilter),
      this.loadDaylogs(period, false, selectedDepartmentIds),
      this.loadOpenShifts(period, false, selectedDepartmentIds),
      this.loadRequiredShifts(period, false, selectedDepartmentIds),
      this.loadEvents(period, false, selectedDepartmentIds),
      this.loadTeamDays(period, false, selectedDepartmentIds),
      this.loadAbsence(period, false),
      this.loadContracts(period, false),
      this.loadAvailabilities(period, false),
      this.loadWeather(period, false, selectedDepartmentIds),
    ]);
  }

  public userDataObs(minDate: string, maxDate: string, userId: string, updateStore = true) {
    const period = {
      minDate: minDate,
      maxDate: maxDate,
      userId,
    };

    return observableForkJoin([
      this.loadSchedules(period, updateStore),
      this.loadAbsence(period, updateStore),
      this.loadAvailabilities(period, updateStore),
    ]);
  }

  private loadSchedules(period, updateStore: boolean, departmentIds?: string[], loanedShiftsFilter?: boolean) {
    const requestData: SchedulesLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
    };

    if (!loanedShiftsFilter) {
      requestData.departmentId = departmentIds;
    }

    return this.scheduleService.load(requestData, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  public loadOpenShifts(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: OpenShiftsLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.store.pipe(
      lazySelect(getAccountSubscription),
      map((subscription) => hasAtleastSubscriptionPlan(PlanType.BASIC, subscription)),
      switchMap((hasBasic: boolean) =>
        hasBasic ? this.openShiftService.load(requestData, updateStore) : of(undefined),
      ),
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadRequiredShifts(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: RequiredShiftsLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.requiredShiftService.load(requestData, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadEvents(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: EventsLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.eventService.load(requestData, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadAbsence(period, updateStore) {
    const check: PermissionCheck = {
      permissions: ['View absentee', 'View own absentee'],
      userId: 'me',
      departments: 'any',
    };

    const searchParams = {
      ...period,
    };

    return this.store.pipe(
      lazySelect(getPermissionState),
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => (hasPerm ? this.absenceService.load(searchParams, updateStore) : of(undefined))),
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadAvailabilities(period, updateStore) {
    return this.availabilityService.load(period, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadWeather(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: WeatherLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.weatherService.load(requestData, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadDaylogs(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: DaylogsLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    return this.daylogService.load(requestData, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadContracts(period, updateStore) {
    return this.contractService.load(period, updateStore).pipe(
      first(),
      catchError(() => of(undefined)),
    );
  }

  private loadTeamDays(period, updateStore: boolean, departmentIds?: string[]) {
    const requestData: TeamDaysLoadRequest = {
      minDate: period.minDate,
      maxDate: period.maxDate,
      departmentId: departmentIds,
    };

    const check: PermissionCheck = {
      permissions: ['View team notes', 'View budget'],
      userId: 'me',
      departments: 'any',
    };

    return this.store.pipe(
      lazySelect(getPermissionState),
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => (hasPerm ? this.teamDayService.load(requestData, updateStore) : of(undefined))),
      first(),
      catchError(() => of(undefined)),
    );
  }
}

export const filterSchedulePeriod = (period: ScheduleFilterPeriod) => {
  if (period.periodType === 'day') {
    // On the day view an exact filter must be applied using both date
    // and starttime / endtime properties. The existing periodFilter using
    // only the date is too lenient, causing extra entities to be
    // provided.
    return periodDateRangeFilter(period.range.start, period.range.end);
  } else {
    // The periodDateRangeFilter used for the day overview would be
    // a performance hit for the week / month overviews, because of the
    // creation of new javascript date objects. Therefore,
    // the existing periodFilter will be used comparing only string based dates.
    return periodFilter(period.minDate, period.maxDate);
  }
};

export const getActiveScheduleTeamsPerDepartment = createSelector(
  getActiveTeamsGroupedByDepartment,
  getSelectedDepartmentIds,
  (activeTeamsPerDepartment, departmentIds): { [departmentId: string]: TeamModel[] } => {
    const teams = activeTeamsPerDepartment as { [departmentId: string]: TeamModel[] };

    return pick(teams, departmentIds);
  },
);

export const getVisibleScheduleTeamsPerDepartment = createSelector(
  getVisibleTeamsGroupedByDepartment(),
  getSelectedDepartmentIds,
  (visibleTeamsPerDepartment, departmentIds): { [departmentId: string]: TeamModel[] } => {
    const teams = visibleTeamsPerDepartment as { [departmentId: string]: TeamModel[] };

    return pick(teams, departmentIds);
  },
);

export const getVisibleScheduleTeamsPerDepartmentForTimesheet = createSelector(
  getVisibleTeamsGroupedByDepartmentForTimesheet,
  getSelectedDepartmentIds,
  (visibleTeamsPerDepartment, departmentIds): { [departmentId: string]: TeamModel[] } => {
    const teams = visibleTeamsPerDepartment as { [departmentId: string]: TeamModel[] };

    return pick(teams, departmentIds);
  },
);

export const getVisibleScheduleTeamsPerDepartmentForDepartment = (id) =>
  createSelector(
    getVisibleTeamsGroupedByDepartment(),
    (
      visibleTeamsPerDepartment,
    ): {
      [departmentId: string]: TeamModel[];
    } => pick(visibleTeamsPerDepartment, id),
  );

export const getContractsForSchedulePerEmployeePerDay = createSelector(
  getSelectedSchedulePeriod,
  getContracts,
  (period, contracts) => {
    const minDate = period.periodType !== 'day' ? period.minDate : period.selectedDate;
    const maxDate = period.periodType !== 'day' ? period.maxDate : period.selectedDate;

    // hours, wage, departmentId, type
    return contractInfoPerEmployeePerDay(minDate, maxDate, contracts);
  },
);

export const getEmployeesForSchedule = createSelector(
  getSelectedSchedulePeriod,
  getSelectedDepartmentIds,
  getPermissionState,
  getEmployeeTeamDepartments,
  getTeamEntities,
  getEmployees,
  getContractsForSchedulePerEmployeePerDay,
  getScheduleFilters,
  getAccountSubscription,
  (
    period: ScheduleFilterPeriod,
    departmentIds: string[],
    permissionState: PermissionState,
    employeeTeamDepartments,
    teamEntities,
    employees: EmployeeModel[],
    contractsPerEmployee: ContractInfoPerEmployeePerDay,
    scheduleFilters,
    subscription: SubscriptionModel,
  ): EmployeeWithContractInfo[] => {
    if (scheduleFilters.employeeSearch && scheduleFilters.employeeSearch.length > 0) {
      employees = employees.filter(
        (employee) =>
          employee.name.toLocaleLowerCase().indexOf(scheduleFilters.employeeSearch.toLocaleLowerCase()) !== -1,
      );
    }
    const canViewScheduleFilter = (employee) => {
      const canViewScheduleCheck: PermissionCheck = {
        permissions: ['View all rosters', 'View own roster', 'View absentee', 'View availability'],
        departments: employeeTeamDepartments[employee.id],
        userId: employee.id,
      };

      return hasPermission(canViewScheduleCheck, permissionState);
    };

    const combinedFilters = [
      employeeInDepartment(departmentIds, teamEntities, null, false),
      employeeActiveInPeriod(period.minDate, period.maxDate),
      canViewScheduleFilter,
    ];

    if (hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, subscription)) {
      combinedFilters.push(employeeSkillFilter(scheduleFilters.skills));
    }

    const filteredEmployees = combinedFilter(employees, ...combinedFilters);

    return filteredEmployees.map((employee: EmployeeModel) => {
      const contractInfoPerDay = contractsPerEmployee[employee.id];
      return {
        id: employee.id,
        name: employee.name,
        first_name: employee.first_name,
        prefix: employee.prefix,
        last_name: employee.last_name,
        email: employee.email,
        avatar_file_name: employee.avatar_file_name,
        avatar_30x30: employee.avatar_30x30,
        startdate: employee.startdate,
        enddate: employee.enddate,
        plus_min_hours: employee.plus_min_hours,
        Team: employee.Team,
        contractInfoPerDay,
        Skill: employee.Skill,
        roster_note: employee.roster_note,
        order: employee.order,
      } as EmployeeWithContractInfo;
    });
  },
);

export const getSelectedScheduledShifts = createSelector(
  getSelectedSchedulePeriod,
  getSelectedDepartmentIds,
  getPermissionState,
  getEmployeesForSchedule,
  getSchedules,
  getTeamEntities,
  getShiftEntities,
  getDepartmentEntities,
  getLocationEntities,
  getScheduleFilters,
  getScheduleConflictEntities,
  getScheduleComplianceEntities,
  (
    period: ScheduleFilterPeriod,
    departmentIds: string[],
    permissionState: PermissionState,
    employees,
    schedules: ScheduleModel[],
    teamEntities,
    shiftEntities,
    departmentEntities,
    locationEntities,
    scheduleFilters,
    conflictEntities: Dictionary<ScheduleConflictStoreModel>,
    complianceEntities,
  ): ScheduleModel[] => {
    const employeeIds = employees.map((employee) => employee.id);

    const canEditSchedule = hasSchedulePermission(['Edit roster', 'Edit own roster'], permissionState);

    const canDeleteSchedule = hasSchedulePermission(['Delete roster', 'Delete own roster'], permissionState);

    const combinedFilters = [filterSchedulePeriod(period), modelEmployeeInList(employeeIds)];

    let filteredSchedules: ScheduleModel[] = combinedFilter(schedules, ...combinedFilters);

    const enhanceFunction = enhanceSchedule(teamEntities, shiftEntities, departmentEntities, locationEntities);

    if (scheduleFilters.showOptions.conflicts) {
      filteredSchedules = filteredSchedules.filter(
        (schedule) => !isEmpty(conflictEntities[schedule.user_id]?.conflicts[schedule.occurrence_id]),
      );
    }

    if (scheduleFilters.showOptions.violationsOnly) {
      filteredSchedules = filteredSchedules.filter(
        (schedule) => !isEmpty(complianceEntities[schedule.user_id]?.violations[schedule.occurrence_id]),
      );
    }

    return filteredSchedules.map((schedule) => {
      const enhancedSchedule = enhanceFunction(schedule);
      return {
        ...enhancedSchedule,
        canEdit: canEditSchedule(enhancedSchedule),
        canDelete: canDeleteSchedule(enhancedSchedule),
      } as ScheduleModel;
    });
  },
);

export const getAbsenceForEmployeeSchedule = createSelector(
  getSelectedSchedulePeriod,
  getPermissionState,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getAbsenceEnhanced,
  getEmployeesForSchedule,
  (period, permissionState, employeeTeamDepartments, absence, employees) => {
    const employeeIds = employees.map((employee) => employee.id);
    absence = absence
      .filter(modelEmployeeInList(employeeIds))
      .filter((absenceRow: EnhancedAbsenceModel) => absenceRow.status !== 'Declined');

    if (period.periodType === 'day') {
      return absenceForDateRange(
        period.range.start,
        period.range.end,
        permissionState,
        employeeTeamDepartments,
        absence,
      );
    } else {
      return absenceForPeriod(period.minDate, period.maxDate, permissionState, employeeTeamDepartments, absence);
    }
  },
);

export const getAvailabilityForSchedule = createSelector(
  getSelectedSchedulePeriod,
  getPermissionState,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getEmployeesForSchedule,
  getAvailabilities,
  (period, permissionState, employeeTeamDepartments, employees, availabilities: AvailabilityModel[]) => {
    const employeeIds = employees.map((employee) => employee.id);
    availabilities = availabilities.filter(modelEmployeeInList(employeeIds));

    return availabilityForPeriod(period, permissionState, employeeTeamDepartments, availabilities);
  },
);

export const getSelectedOpenShiftsPerTeam = (departmentId: string) =>
  createSelector(
    getSelectedSchedulePeriod,
    getPermissionState,
    getEnhancedOpenShifts,
    getScheduleFilters,
    getOpenShiftState,
    getTeamEntities,
    getShiftEntities,
    getDepartmentEntities,
    getLocationEntities,
    getAuthenticatedUserId,
    getEmployeeEntities,
    (
      period: ScheduleFilterPeriod,
      permissionState: PermissionState,
      openShifts: OpenShiftModel[],
      filters: ScheduleFilterState,
      state,
      teamEntities,
      shiftEntities,
      departmentEntities,
      locationEntities,
      authenticatedUserId,
      employeeEntities,
    ): { [teamId: string]: OpenShiftModel[] } => {
      if (!filters.showTypes.openShifts) {
        return {};
      }

      const shiftFilters = get(filters, ['department', departmentId, 'shifts'], []);

      const combinedFilters = [
        (openShift: OpenShiftModel) => openShift.department_id === departmentId,
        (schedule: OpenShiftModel) => {
          if (shiftFilters.length === 0) {
            return true;
          }
          return shiftFilters.includes(schedule.shift_id);
        },
        filterSchedulePeriod(period),
        hasOpenShiftPermission(['View all rosters', 'View own roster'], permissionState),
      ];

      const filteredShifts = combinedFilter(openShifts, ...combinedFilters);

      const enhanceFunction = enhanceOpenShift(
        state,
        teamEntities,
        shiftEntities,
        departmentEntities,
        locationEntities,
        authenticatedUserId,
        employeeEntities,
      );

      const permissionShifts = filteredShifts.map((shift) => {
        const enhancedShift = enhanceFunction(shift);

        const canEdit = hasPermission(
          {
            permissions: ['Edit shifts'],
            userId: 'me',
            departments: enhancedShift.department_id,
          },
          permissionState,
        );

        const canDelete = hasPermission(
          {
            permissions: ['Delete shifts'],
            userId: 'me',
            departments: enhancedShift.department_id,
          },
          permissionState,
        );

        return {
          ...enhancedShift,
          canEdit,
          canDelete,
        };
      });

      return groupBy(permissionShifts, 'team_id');
    },
  );

export const getSelectedRequiredShiftsPerTeam = (departmentId: string) =>
  createSelector(
    getSelectedSchedulePeriod,
    getPermissionState,
    getRequiredShifts,
    getScheduleFilters,
    getRequiredShiftState,
    getTeamEntities,
    getShiftEntities,
    getDepartmentEntities,
    getLocationEntities,
    (
      period: ScheduleFilterPeriod,
      permissionState: PermissionState,
      requiredShifts: RequiredShiftModel[],
      filters: ScheduleFilterState,
      state,
      teamEntities,
      shiftEntities,
      departmentEntities,
      locationEntities,
    ): { [teamId: string]: RequiredShiftModel[] } => {
      if (!filters.showTypes.requiredShifts) {
        return {};
      }

      const shiftFilters = get(filters, ['department', departmentId, 'shifts'], []);

      const combinedFilters = [
        (requiredShift: RequiredShiftModel) => requiredShift.department_id === departmentId,
        (schedule: RequiredShiftModel) => {
          if (shiftFilters.length === 0) {
            return true;
          }
          return shiftFilters.includes(schedule.shift_id);
        },
        filterSchedulePeriod(period),
        hasRequiredShiftPermission(['View required shifts'], permissionState),
      ];

      const filteredShifts = combinedFilter(requiredShifts, ...combinedFilters);

      const enhanceFunction = enhanceRequiredShift(
        state,
        teamEntities,
        shiftEntities,
        departmentEntities,
        locationEntities,
      );

      const permissionShifts = filteredShifts.map((shift) => {
        const enhancedShift = enhanceFunction(shift);
        const canEdit = hasPermission(
          {
            permissions: ['Edit required shift'],
            userId: 'me',
            departments: enhancedShift.department_id,
          },
          permissionState,
        );

        const canDelete = hasPermission(
          {
            permissions: ['Delete required shift'],
            userId: 'me',
            departments: enhancedShift.department_id,
          },
          permissionState,
        );

        return {
          ...enhancedShift,
          canEdit,
          canDelete,
        };
      });

      return groupBy(permissionShifts, 'team_id');
    },
  );

export const getEventsPerDepartmentForSchedule = (departmentId: string) =>
  createSelector(
    getSelectedSchedulePeriod,
    getPermissionState,
    getEvents,
    (period: ScheduleFilterPeriod, permissionState: PermissionState, events) => {
      const combinedFilters = [
        filterSchedulePeriod(period),
        (event: EventModel) => event.department_id === departmentId,
      ];

      const filteredEvents = combinedFilter(events, ...combinedFilters);

      const permissionShifts = filteredEvents.map((event) => {
        const canEdit = hasPermission(
          {
            permissions: ['Edit event'],
            userId: 'me',
            departments: event.department_id,
          },
          permissionState,
        );

        const canDelete = hasPermission(
          {
            permissions: ['Delete event'],
            userId: 'me',
            departments: event.department_id,
          },
          permissionState,
        );

        return {
          ...event,
          canEdit,
          canDelete,
        };
      });

      return groupBy(permissionShifts, 'department_id');
    },
  );

export const getHolidaysForSchedule = createSelector(
  getSelectedSchedulePeriod,
  getHolidays,
  (period: ScheduleFilterPeriod, holidays: HolidayModel[]) =>
    holidays.filter(periodFilter(period.minDate, period.maxDate)),
);

export const getTeamDaysForSchedule = (departmentId) =>
  createSelector(
    getSelectedSchedulePeriod,
    getVisibleTeamsGroupedByDepartment(),
    getTeamDays,
    (
      period,
      teamsPerDepartment: { [departmentId: string]: TeamModel[] },
      teamDays,
    ): { [teamId: string]: TeamDayModel[] } => {
      const teams = teamsPerDepartment[departmentId] || [];
      const teamIds = teams.map((team) => team.id);

      const combinedFilters = [
        periodFilter(period.minDate, period.maxDate),
        (teamDay: TeamDayModel) => teamIds.indexOf(teamDay.team_id) !== -1,
      ];

      const filteredTeamDays = combinedFilter(teamDays, ...combinedFilters);

      return groupBy(filteredTeamDays, 'team_id');
    },
  );

export const getVisibleTeamsForDepartment = (id: string) =>
  createSelector(getVisibleTeams(), getScheduleFilters, (teams, filters) => {
    const selectedTeams = get(filters, ['department', id, 'teams'], []);

    return teams
      .filter((team) => team.department_id === id)
      .filter((team) => {
        //show all teams if no filter is selected
        if (selectedTeams.length === 0) {
          return true;
        }

        return selectedTeams.includes(team.id);
      });
  });

export const normalizePosition = (position) => {
  if (isNaN(position) || position === -Infinity || position === Infinity) {
    return 0;
  }

  return position;
};

export const getSchedulesForSchedulePeriod = createSelector(
  getSelectedSchedulePeriod,
  getEnhancedSchedules,
  getAvailabilityForSchedule,
  getAbsenceForEmployeeSchedule,
  getEmployeeEntities,
  getSelectedDepartmentIds,
  (
    schedulePeriod: ScheduleFilterPeriod,
    schedules: ScheduleModel,
    availabilities: AvailabilityModel[],
    absences: AbsenceModel[],
    employeeEntities: Dictionary<EmployeeModel>,
    selectedDepartmentIds: string[],
  ) =>
    schedules.filter((schedule: ScheduleModel) => {
      if (schedule.Shift?.is_task) {
        return false;
      }

      if (!selectedDepartmentIds.includes(schedule?.department_id || schedule?.Department?.id)) {
        return false;
      }
      return entityInPeriod(schedulePeriod.minDate, schedulePeriod.maxDate, schedule.date);
    }),
);

export const getUnfulfilledRequiredShiftForSchedulePeriod = createSelector(
  getSelectedSchedulePeriod,
  getEnhancedRequiredShifts,
  getSelectedDepartmentIds,
  getAllRequiredShiftFulfillment,
  (
    schedulePeriod: ScheduleFilterPeriod,
    requiredShifts: RequiredShiftModel[],
    selectedDepartmentIds: string[],
    fulfillmentEntities: Dictionary<RequiredShiftFulfillmentModel>,
  ) =>
    requiredShifts.filter((requiredShift: RequiredShiftModel) => {
      if (requiredShift?.team_id && requiredShift?.Team?.deleted) {
        return false;
      }

      if (!selectedDepartmentIds.includes(requiredShift.department_id)) {
        return false;
      }
      return (
        !fulfillmentEntities[requiredShift.occurrence_id]?.fulfilled &&
        entityInPeriod(schedulePeriod.minDate, schedulePeriod.maxDate, requiredShift.date)
      );
    }),
);

export const getEnhancedOpenShiftInstancesForSchedulePeriod = createSelector(
  getSelectedSchedulePeriod,
  getEnhancedOpenShifts,
  getSelectedDepartmentIds,
  (schedulePeriod: ScheduleFilterPeriod, openShifts: OpenShiftModel[], selectedDepartmentIds: string[]) => {
    const openShiftInstances = openShifts
      .filter((openShift: OpenShiftModel) => {
        if (openShift.team_id && openShift?.Team?.deleted) {
          return false;
        }

        if (!selectedDepartmentIds.includes(openShift.department_id)) {
          return false;
        }
        return entityInPeriod(schedulePeriod.minDate, schedulePeriod.maxDate, openShift.date);
      })
      .map((openShift: OpenShiftModel) => parseInt(<string>(<unknown>openShift.instances_remaining), 10));

    return openShiftInstances.reduce((acc, cur) => acc + cur, 0);
  },
);

export const getDataForStatusBar = createSelector(
  getSchedulesForSchedulePeriod,
  getUnfulfilledRequiredShiftForSchedulePeriod,
  getEnhancedOpenShiftInstancesForSchedulePeriod,
  getSelectedDepartments,
  getScheduleViolationsCountForSelectedDepartments,
  getScheduleConflictsForStatusBar,
  (
    schedules: ScheduleModel,
    requiredShifts: RequiredShiftModel[],
    openShiftInstances: number,
    selectedDepartments: DepartmentModel[],
    violationsCount: number,
    scheduleConflicts: Dictionary<ScheduleConflictModel[]>,
  ): ScheduleStatusModel => {
    const conflicts = schedules.filter(
      (schedule: ScheduleModel) => scheduleConflicts[schedule.occurrence_id]?.length > 0,
    );
    return {
      requiredShiftsCount: requiredShifts.length,
      openShiftsCount: openShiftInstances,
      schedulesCount: schedules.length,
      conflictsCount: conflicts.length,
      violationsCount: violationsCount,
      requiredShiftsEnabled: selectedDepartments.some((department: DepartmentModel) => department.show_required_shifts),
      openShiftsEnabled: selectedDepartments.some((department: DepartmentModel) => department.show_open_shifts),
    };
  },
);

export const canViewStatusBar = createSelector(getPermissionState, (permissionState: PermissionState): boolean =>
  hasPermission(
    {
      permissions: [
        'Create roster',
        'Create own roster',
        'Edit roster',
        'Edit own roster',
        'Delete roster',
        'Delete own roster',
      ],
      departments: 'any',
      userId: 'me',
    },
    permissionState,
  ),
);
