/* eslint-disable max-lines */
import { Dictionary, EntityAdapter } from '@ngrx/entity';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';

import { SCHEDULE_ROW_HEIGHT } from '../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { getDayListWithoutTotals } from '../../+authenticated/+schedule/employee/schedule-employee-period/schedule-employee-period.helper';
import { parseDate } from '../../shared/date.helper';
import { Totals } from '../../shared/interfaces';
import { AbsenceModel, AbsenteeDay } from '../orm/absence/absence.model';
import { EmployeeModel } from '../orm/employee/employee.model';
import { OpenShiftModel } from '../orm/open-shift/open-shift.model';
import { RequiredShiftModel } from '../orm/required-shift/required-shift.model';
import { ScheduleModel } from '../orm/schedule/schedule.model';
import { TeamDayModel } from '../orm/team-day/team-day.model';
import { convertArrayToObject } from '../shared/entity.helper';
import { DepartmentDataNState } from './departmentdata/department-data.n.state';
import { BudgetRowModel, TeamBudgetDay, TeamDaysDay, TeamDaysRowModel, TotalsRowModel } from './schedule-helper.model';
import {
  calculateAbsenteeDayTotal,
  calculateScheduleRowHeight,
  calculateScheduleTotal,
  calculateSingleItemRowHeight,
} from './schedule-shared.helper';
import { ScheduleNState } from './schedule/schedule.n.state';
import { TeamDataNState } from './teamdata/team-data.n.state';

export const removeSchedule = (
  occurrenceId: string,
  entityId: string,
  adapter: EntityAdapter<any>,
  state: DepartmentDataNState | TeamDataNState,
  recurring = false,
) => {
  if (!entityId || !state.entities[entityId]) {
    return state;
  }

  const entity = state.entities[entityId];

  const curSchedule = entity.schedules[occurrenceId];
  const idsToDelete = [occurrenceId];
  if (curSchedule && recurring) {
    idsToDelete.push(...getScheduleIdsToDelete(curSchedule, entity.schedules));
  }

  const updatedData = {
    ...entity,
    id: entityId,
    schedules: {
      ...omit(entity.schedules, idsToDelete),
    },
  };
  updatedData.filteredSchedules = getFilteredSchedules(updatedData.schedules, state, {});

  if (updatedData.hasOwnProperty('scheduleRowHeight')) {
    const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
    updatedData['rowHeight'] = calculateScheduleRowHeight(
      {
        schedules: updatedData.filteredSchedules,
        absence: {},
        availabilities: {},
      },
      totals,
      state.showTypes,
      state.showOptions,
    );
  }

  return adapter.setOne(updatedData, state);
};

export const addSchedules = (
  schedules: ScheduleModel[],
  entityId: string,
  adapter: EntityAdapter<any>,
  state: DepartmentDataNState | TeamDataNState,
  defaultDataFunc: Function,
) => {
  if (!entityId) {
    return state;
  }

  const schedulesInPeriod = schedules.filter((schedule: ScheduleModel) =>
    entityInPeriod(state.minDate, state.maxDate, schedule.date),
  );

  const entity = state.entities[entityId];

  const updatedData = {
    ...(entity ?? defaultDataFunc(entityId)),
    id: entityId,
    schedules: {
      ...entity?.schedules,
      ...convertArrayToObject('occurrence_id', schedulesInPeriod),
    },
  };
  updatedData.filteredSchedules = getFilteredSchedules(updatedData.schedules, state, {});

  if (updatedData.hasOwnProperty('scheduleRowHeight')) {
    const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
    updatedData['scheduleRowHeight'] = calculateScheduleRowHeight(
      {
        schedules: updatedData.filteredSchedules,
        absence: {},
        availabilities: {},
      },
      totals,
      state.showTypes,
      state.showOptions,
    );
  }

  return adapter.setOne(updatedData, state);
};

export const updateRequiredShift = (
  loadingId: string,
  occurrenceId: string,
  requiredShift: RequiredShiftModel,
  state: TeamDataNState | DepartmentDataNState,
  loading = false,
  adapter: EntityAdapter<any>,
  defaultDataFunc: Function,
) => {
  const entityId = requiredShift?.team_id ?? requiredShift?.department_id;
  let data = cloneDeep(state.entities[entityId]);
  if (!data) {
    data = defaultDataFunc(entityId);
  }

  let oldShift = data.requiredShifts[occurrenceId];
  if (
    requiredShift.TeamJoin &&
    requiredShift.team_id !== requiredShift.TeamJoin?.id &&
    state.entities[requiredShift.TeamJoin?.id]
  ) {
    oldShift = state.entities[requiredShift.TeamJoin?.id].requiredShifts[occurrenceId];

    state = removeRequiredShift(occurrenceId, requiredShift.TeamJoin.id, state, adapter);
  }

  if (!entityInPeriod(state.minDate, state.maxDate, requiredShift.date)) {
    return state;
  }

  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
  data.requiredShifts = omit(data.requiredShifts, occurrenceId);
  data.requiredShifts[loadingId] = { ...oldShift, ...requiredShift, loading };
  data.filteredRequiredShifts = getFilteredShifts(data.requiredShifts, state.shiftFilters);
  data.requiredShiftsRowHeight = calculateSingleItemRowHeight(data.filteredRequiredShifts, totals);
  return adapter.setOne(data, state);
};

export const removeRequiredShift = (
  occurrenceId: string,
  entityId: string,
  state: TeamDataNState | DepartmentDataNState,
  adapter: EntityAdapter<any>,
  recurring: boolean = false,
) => {
  const entity = state.entities[entityId];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const curSchedule = entity?.requiredShifts[occurrenceId];
  const idsToDelete = [occurrenceId];
  if (curSchedule && recurring) {
    idsToDelete.push(...getScheduleIdsToDelete(curSchedule, entity?.requiredShifts));
  }

  const updatedRequiredShifts = {
    ...omit(entity?.requiredShifts, idsToDelete),
  };
  const updatedData = {
    ...entity,
    id: entityId,
    requiredShifts: updatedRequiredShifts,
  };
  updatedData.filteredRequiredShifts = getFilteredShifts(updatedData.requiredShifts, state.shiftFilters);
  updatedData.requiredShiftsRowHeight = calculateSingleItemRowHeight(updatedData.requiredShifts, totals);

  return adapter.setOne(updatedData, state);
};

export const addRequiredShifts = (
  entityId: string,
  shifts: RequiredShiftModel[],
  state: TeamDataNState | DepartmentDataNState,
  adapter: EntityAdapter<any>,
  defaultDataFunc: Function,
) => {
  const entity = state.entities[entityId];

  const shiftsInPeriod = shifts.filter((requiredShift: RequiredShiftModel) =>
    entityInPeriod(state.minDate, state.maxDate, requiredShift.date),
  );
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedData = {
    ...(entity ?? defaultDataFunc(entityId)),
    id: entityId,
    requiredShifts: {
      ...entity?.requiredShifts,
      ...convertArrayToObject('occurrence_id', shiftsInPeriod),
    },
  };
  updatedData.filteredRequiredShifts = getFilteredShifts(updatedData.requiredShifts, state.shiftFilters);
  updatedData.requiredShiftsRowHeight = calculateSingleItemRowHeight(updatedData.requiredShifts, totals);

  return adapter.setOne(updatedData, state);
};

export const getFilteredSchedules = (
  schedules: Dictionary<ScheduleModel>,
  state: ScheduleNState | TeamDataNState | DepartmentDataNState,
  absences: Dictionary<AbsenceModel>,
) => {
  const { shiftFilters, showTypes } = state;
  const scheduleArray = Object.values(schedules ?? {});
  const absenceArray = Object.values(absences ?? {});

  return scheduleArray.filter((schedule: ScheduleModel) => {
    if (!showTypes?.scheduledShifts) {
      return false;
    }

    if (!shiftFilters[schedule.department_id] || shiftFilters[schedule.department_id].length === 0) {
      return true;
    }
    return shiftFilters[schedule.department_id].includes(schedule.shift_id);
  });
};

export const getFilteredShifts = (
  shifts: Dictionary<OpenShiftModel> | Dictionary<RequiredShiftModel> | Dictionary<ScheduleModel>,
  shiftFilters: string[],
  teamFilters: string[] = [],
) => {
  const scheduleArray = Object.values(shifts ?? {});

  return scheduleArray.filter((shift: OpenShiftModel | RequiredShiftModel) => {
    const teamIdFilter = teamFilters[shift.department_id];
    if (shift.team_id && teamIdFilter?.length > 0) {
      if (!teamIdFilter.includes(shift.team_id)) {
        return false;
      }
    }

    const shiftIdFilters = shiftFilters[shift.department_id];
    if (shift.shift_id && shiftIdFilters?.length > 0) {
      if (!shiftIdFilters.includes(shift.shift_id)) {
        return false;
      }
    }

    return true;
  });
};

export const addRequiredShift = (
  occurrenceId: string,
  shift: RequiredShiftModel,
  loading: boolean,
  state: DepartmentDataNState | TeamDataNState,
  adapter: EntityAdapter<any>,
  createDefault: Function,
) => {
  if (!entityInPeriod(state.minDate, state.maxDate, shift.date)) {
    return state;
  }
  const entityId = shift?.team_id ?? shift.department_id;
  const entity = state.entities[entityId];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedShift = {
    ...shift,
    occurrence_id: occurrenceId,
    loading,
  };

  const updatedData = {
    ...(entity ?? createDefault(entityId)),
    id: entityId,
    requiredShifts: {
      ...entity?.requiredShifts,
      [occurrenceId]: updatedShift,
    },
  };
  updatedData.filteredRequiredShifts = getFilteredShifts(updatedData.requiredShifts, state.shiftFilters);
  updatedData.requiredShiftsRowHeight = calculateSingleItemRowHeight(updatedData.filteredRequiredShifts, totals);

  return adapter.setOne(updatedData, state);
};

export const calculateBudget = (teamDays: TeamDaysRowModel, teamTotal: TotalsRowModel): BudgetRowModel => {
  const totalsPerDay = Object.values(teamDays.totalsPerDay).map(
    (total: TeamDaysDay, index): TeamBudgetDay => ({
      date: total.date,
      totals: total.totals,
      overBudget: {
        hours: teamTotal.totalsPerDay[index].totals.hours > total.totals.hours,
        pay: teamTotal.totalsPerDay[index].totals.pay > total.totals.pay,
      },
    }),
  );
  return {
    id: teamDays.id,
    totals: teamDays.totals,
    totalsPerDay,
    overBudget: {
      pay: teamTotal.totals.pay > teamDays.totals.pay,
      hours: teamTotal.totals.hours > teamDays.totals.hours,
    },
    rowHeight: SCHEDULE_ROW_HEIGHT,
  };
};

export const calculateFinalTotals = (
  scheduleGroupId: string,
  absenceGroupId: string,
  scheduleTotals,
  absenceTotals,
  totals,
): TotalsRowModel => {
  const absence = absenceTotals[absenceGroupId];
  const schedules = scheduleTotals;
  const consolidatedTotals = {
    hours: (schedules?.totals?.hours ?? 0) + (absence?.totals?.hours ?? 0),
    pay: (schedules?.totals?.pay ?? 0) + (absence?.totals?.pay ?? 0),
  };

  const teamDayTotals = totals.map((total, index) => {
    const absenceTotal = absence?.dayTotals[index]?.dayTotal;
    const scheduleTotal = schedules?.totalsPerDay[index]?.totals;

    return {
      ...total,
      totals: {
        hours: (absenceTotal?.hours ?? 0) + (scheduleTotal?.hours ?? 0),
        pay: (absenceTotal?.pay ?? 0) + (scheduleTotal?.pay ?? 0),
      },
    };
  });

  return {
    totalsPerDay: teamDayTotals,
    totals: consolidatedTotals,
    id: scheduleGroupId,
    rowHeight: SCHEDULE_ROW_HEIGHT,
  };
};

export const calculateTotals = (
  entityId: string,
  totals,
  entities: ScheduleModel[],
  cocInSchedule: boolean,
): TotalsRowModel => {
  const consolidatedTotals = {
    hours: 0,
    pay: 0,
  };

  const dayTotals = totals.map((total) => {
    const dayEntities = entities.filter((entity) => entity.date === total.date);
    const entityTotal = calculateScheduleTotal(dayEntities, cocInSchedule);

    consolidatedTotals.hours += entityTotal.hours;
    consolidatedTotals.pay += entityTotal.pay;
    return {
      ...total,
      totals: entityTotal,
    };
  });

  return {
    id: entityId,
    totals: consolidatedTotals,
    totalsPerDay: dayTotals,
    rowHeight: SCHEDULE_ROW_HEIGHT,
  };
};

export const calculateAbsenceTotals = (
  entityId: string,
  totals,
  absences: AbsenceModel[],
  employeeEntities: Dictionary<EmployeeModel>,
): TotalsRowModel => {
  const consolidatedTotals = {
    hours: 0,
    pay: 0,
  };

  const absenteeDays = absences
    .filter((absence: AbsenceModel) => absence.status === 'Approved')
    .map((absence: AbsenceModel) =>
      Object.values(absence.AbsenteeDay).map((absenceDay: AbsenteeDay) => ({
        ...absenceDay,
        user_id: absence.user_id,
      })),
    )
    .flat(1);

  const dayTotals = totals.map((total) => {
    const days = absenteeDays.filter((day: AbsenteeDay) => {
      const endDate = employeeEntities[day.user_id]?.enddate;
      return (!endDate || day.date < endDate) && day.date === total.date;
    });
    const entityTotal = calculateAbsenteeDayTotal(entityId, days);

    consolidatedTotals.hours += entityTotal.hours;
    consolidatedTotals.pay += entityTotal.pay;
    return {
      ...total,
      totals: entityTotal,
    };
  });

  return {
    id: entityId,
    totals: consolidatedTotals,
    totalsPerDay: dayTotals,
    rowHeight: SCHEDULE_ROW_HEIGHT,
  };
};

export const processTeamDays = (entityId: string, teamDays: TeamDayModel[], totals): TeamDaysRowModel => {
  const teamTotal: Totals = {
    hours: 0,
    pay: 0,
  };
  const updatedTotals = totals.map((total): TeamDaysDay => {
    const teamDaysPerDate = teamDays.filter((day: TeamDayModel) => day.date === total.date);
    const calculatedTotals = teamDaysPerDate.reduce(
      (acc: Totals, teamDay: TeamDayModel) => {
        acc.hours += parseFloat(teamDay.budget_time ?? '0');
        acc.pay += parseFloat(teamDay.budget_cost ?? '0');
        return acc;
      },
      { hours: 0, pay: 0 },
    );

    teamTotal.hours += calculatedTotals.hours;
    teamTotal.pay += calculatedTotals.pay;
    return {
      date: total.date,
      teamDay: teamDaysPerDate[0],
      totals: {
        hours: calculatedTotals.hours,
        pay: calculatedTotals.pay,
      },
    };
  });

  return {
    totalsPerDay: updatedTotals,
    id: entityId,
    totals: teamTotal,
    rowHeight: SCHEDULE_ROW_HEIGHT,
  };
};

export const getScheduleIdsToDelete = (
  currentSchedule: ScheduleModel | OpenShiftModel | RequiredShiftModel,
  scheduleEntities: Dictionary<ScheduleModel> | Dictionary<RequiredShiftModel> | Dictionary<OpenShiftModel>,
) =>
  Object.values(scheduleEntities ?? {})
    .filter((schedule) => schedule.id === currentSchedule.id && schedule.date > currentSchedule.date)
    .map((schedule) => schedule.occurrence_id);

export const entityInPeriod = (minDate: string, maxDate: string, entityDate: string) =>
  entityDate >= minDate && entityDate <= maxDate;

export const exchangeAccepted = (exchange, entityId, state, adapter: EntityAdapter<any>, defaultDataFunc: Function) => {
  if (!entityId) {
    return state;
  }

  if (exchange?.status !== 'Approved') {
    return state;
  }

  const updatedState = removeSchedule(exchange.Roster, entityId, adapter, state);
  return addSchedules([exchange.NewRoster], entityId, adapter, updatedState, defaultDataFunc);
};
