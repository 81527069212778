import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { periodFilter } from '../../../shared/date.helper';
import { AppState } from '../../index';
import { departmentFilter, getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { combinedFilter, mapEntity } from '../../shared/entity.helper';
import { getTeamEntities } from '../team/team.service';
import { TeamDayAction } from './team-day.action';
import { TeamDayApi, TeamDaysLoadRequest } from './team-day.api';
import { TeamDayModel, TeamDayState } from './team-day.model';

@Injectable()
export class TeamDayService {
  public constructor(
    private store: Store<AppState>,
    private api: TeamDayApi,
  ) {}

  public load(requestData: TeamDaysLoadRequest, updateStore = true) {
    return this.api.load(requestData, TeamDayAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(TeamDayAction.loadSuccess(response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(teamDayData): Observable<any> {
    return this.api.add(teamDayData, TeamDayAction.add(teamDayData)).pipe(
      map((response) => {
        this.store.dispatch(TeamDayAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, teamDayData: TeamDayModel) {
    return this.api.update(id, teamDayData, TeamDayAction.update(teamDayData)).pipe(
      map((response) => {
        this.store.dispatch(TeamDayAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    return this.api.fetch(id, TeamDayAction.fetch(id)).pipe(
      map((response) => {
        this.store.dispatch(TeamDayAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    this.store.dispatch(TeamDayAction.remove(id));

    return this.api.remove(id).pipe(
      map((response) => {
        this.store.dispatch(TeamDayAction.removeSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public batch(teamDayData) {
    const data = { TeamDay: teamDayData };

    return this.api.batch(data).pipe(
      tap((response) => {
        this.store.dispatch(TeamDayAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(TeamDayAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public save(teamDayData) {
    if (teamDayData.id) {
      return this.update(teamDayData.id, teamDayData);
    }

    teamDayData = u.omit('id', teamDayData);

    return this.add(teamDayData);
  }
}

export const getTeamDayState = (appState: AppState): TeamDayState => appState.orm.teamDays;

export const getTeamDayIds = compose((state) => state.items, getTeamDayState);
export const getTeamDayEntities = createSelector(getTeamDayState, (state) => state.itemsById);

export const getTeamDays = createSelector(getTeamDayState, (state: TeamDayState) => Object.values(state.itemsById));
export const getTeamDay = (id: string) => createSelector(getTeamDayEntities, (entities) => mapEntity(id, entities));

export const getTeamDaysForPeriodAndSelectedDepartments = (minDate, maxDate) =>
  createSelector(getTeamDays, getTeamEntities, getSelectedDepartmentIds, (teamDays, teams, departmentIds) => {
    const depFilter = departmentFilter(departmentIds);

    const combinedFilters = [
      (teamDay: TeamDayModel) => depFilter(teams[teamDay.team_id]),
      periodFilter(minDate, maxDate),
    ];

    return combinedFilter(teamDays, ...combinedFilters);
  });
