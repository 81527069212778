import { UnsafeAction as Action } from '../../interfaces';
import { ScheduleComplianceModel } from './schedule-compliance.model';

const ACTION_PREFIX = '[ScheduleCompliance]';

export const scheduleComplianceActionType = {
  RESET: `${ACTION_PREFIX} Reset`,
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,
};

export class ScheduleComplianceAction {
  static reset(): Action {
    return {
      type: scheduleComplianceActionType.RESET,
    };
  }

  static load(): Action {
    return {
      type: scheduleComplianceActionType.LOAD,
    };
  }

  static loadSuccess(scheduleCompliances: ScheduleComplianceModel[]): Action {
    return {
      type: scheduleComplianceActionType.LOAD_SUCCESS,
      payload: scheduleCompliances,
    };
  }

  static loadFailed(err): Action {
    return {
      type: scheduleComplianceActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static update(userIds: string[], timestamp: number): Action {
    return {
      type: scheduleComplianceActionType.UPDATE,
      payload: userIds,
      timestamp,
    };
  }

  static updateSuccess(scheduleCompliances: ScheduleComplianceModel[], timestamp: number): Action {
    return {
      type: scheduleComplianceActionType.UPDATE_SUCCESS,
      payload: scheduleCompliances,
      timestamp,
    };
  }

  static updateFailed(err): Action {
    return {
      type: scheduleComplianceActionType.UPDATE_FAILED,
      payload: err,
    };
  }
}
