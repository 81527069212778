import {
  GENERIC_SIZE,
  SCHEDULE_ITEM_SIZE,
  SCHEDULE_ROW_HEIGHT,
  SCHEDULE_ROW_HEIGHT_MARGIN,
  TEAM_DEPARTMENT_ROW_ITEM_MARGIN,
  TOTALS_SIZE,
} from '../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { DayWithoutTotal } from '../../+authenticated/+schedule/shared/schedule.interfaces';
import { defaultTotal, totalAccumulator } from '../../shared/total.helper';
import { isAbsenceWithoutValueHidden } from '../orm/absence/absence.helper';
import { AbsenceModel, AbsenceStatus } from '../orm/absence/absence.model';
import { ScheduleModel } from '../orm/schedule/schedule.model';
import { PayloadKeyType } from '../shared/entity.helper';

export const update = (
  aggregrationObject,
  entities: any[],
  type: PayloadKeyType,
  entityId: string = 'id',
  groupById: string = 'user_id',
) => {
  entities.forEach((entity) => {
    if (type === PayloadKeyType.REQUIRED_SHIFTS && groupById === 'department_id' && entity.team_id !== null) {
      return;
    }
    const user = aggregrationObject[entity[groupById]];
    if (!user) {
      aggregrationObject[entity[groupById]] = createNewModel(entity[groupById]);
    }
    aggregrationObject[entity[groupById]][type][entity[entityId]] = entity;
  });

  return aggregrationObject;
};

export const createNewModel = (groupById: string) => ({
  id: groupById,
  schedules: {},
  absence: {},
  availabilities: {},
  requiredShifts: {},
  openShifts: {},
  events: {},
  teamDays: {},
  daylogs: {},
});

export const calculateAbsenceTotal = (absence: AbsenceModel[], date: string, departmentId: string = null) =>
  absence.reduce((acc, absentee) => {
    if (absentee.status !== 'Approved') {
      return acc;
    }

    const absenceDepartmentId = Object.values(absentee.AbsenteeDay)[0]?.department_id;
    if (departmentId && departmentId !== absenceDepartmentId) {
      return acc;
    }

    const absenceTotal = {
      hours: parseFloat(absentee.AbsenteeDay[date].hours),
      pay: parseFloat(absentee.AbsenteeDay[date].salary),
    };

    return totalAccumulator(acc, absenceTotal);
  }, defaultTotal);

export const calculateAbsenteeDayTotal = (entityId, absenteeDays) =>
  absenteeDays.reduce((acc, absenteeDay) => {
    if (entityId !== absenteeDay.department_id) {
      return acc;
    }
    const absenceTotal = {
      hours: parseFloat(absenteeDay.hours),
      pay: parseFloat(absenteeDay.salary),
    };

    return totalAccumulator(acc, absenceTotal);
  }, defaultTotal);

export const calculateSingleItemRowHeight = (
  data: any,
  totals: DayWithoutTotal[],
  returnZeroIfEmpty: boolean = false,
) => {
  const shifts = Object.values(data ?? []);

  if (returnZeroIfEmpty && shifts?.length === 0) {
    return 0;
  }

  let rowHeight = SCHEDULE_ROW_HEIGHT;

  totals.forEach((total) => {
    const daySchedules = shifts.filter((schedule: any) => schedule.date === total.date);

    let maxDayHeight = daySchedules.length * SCHEDULE_ITEM_SIZE;

    if (maxDayHeight < SCHEDULE_ROW_HEIGHT) {
      maxDayHeight = SCHEDULE_ROW_HEIGHT;
    }

    if (maxDayHeight > rowHeight) {
      rowHeight = maxDayHeight;
    }
  });
  return rowHeight + SCHEDULE_ROW_HEIGHT_MARGIN;
};

export const calculateAbsenceRowHeightForTeam = (data, totals) => {
  const absences = Object.values(data ?? []);
  const hasAbsences = absences.filter((absence: AbsenceModel) => absence.status !== AbsenceStatus.DECLINED).length > 0;

  if (!hasAbsences) {
    return 0;
  }

  let rowHeight = SCHEDULE_ROW_HEIGHT;

  totals.forEach((total) => {
    const daySchedules = absences.filter((absence: AbsenceModel) => {
      if (absence.status === 'Declined') {
        return false;
      }

      const day = absence.AbsenteeDay[total.date];

      if (day && isAbsenceWithoutValueHidden(absence, day)) {
        return false;
      }
      return absence.AbsenteeDay.hasOwnProperty(total.date);
    });

    let maxDayHeight = TEAM_DEPARTMENT_ROW_ITEM_MARGIN + daySchedules.length * SCHEDULE_ITEM_SIZE;

    if (maxDayHeight < SCHEDULE_ROW_HEIGHT) {
      maxDayHeight = SCHEDULE_ROW_HEIGHT;
    }

    if (maxDayHeight > rowHeight) {
      rowHeight = maxDayHeight;
    }
  });

  return rowHeight;
};

export const calculateScheduleRowHeight = (data, totals, showTypes?, showOptions?) => {
  const schedules = Object.values(data.schedules ?? []);
  let absences = [];
  let availabilities = [];

  if (showTypes?.absence || !showTypes) {
    absences = Object.values(data.absence ?? []);
  }
  if (showTypes?.availability || !showTypes) {
    availabilities = Object.values(data.availabilities ?? []);
  }
  let rowHeight = SCHEDULE_ROW_HEIGHT;

  totals.forEach((total) => {
    const dayAbsences = absences.filter((absence: any) => {
      if (absence.status === 'Declined') {
        return false;
      }
      const day = absence.AbsenteeDay[total.date];
      if (day && isAbsenceWithoutValueHidden(absence, day)) {
        return false;
      }
      return absence.AbsenteeDay.hasOwnProperty(total.date);
    });

    const daySchedules = schedules.filter((schedule: any) => schedule.date === total.date);
    const dayAvailabilities = availabilities.filter((availability: any) => availability.date === total.date);

    let maxDayHeight =
      daySchedules.length * SCHEDULE_ITEM_SIZE +
      dayAbsences.length * SCHEDULE_ITEM_SIZE +
      dayAvailabilities.length * GENERIC_SIZE;

    if (maxDayHeight < SCHEDULE_ROW_HEIGHT) {
      maxDayHeight = SCHEDULE_ROW_HEIGHT;
    }

    if (maxDayHeight > rowHeight) {
      rowHeight = maxDayHeight;
    }
  });

  if (showOptions?.totals) {
    rowHeight += TOTALS_SIZE;
  }

  if (rowHeight < SCHEDULE_ROW_HEIGHT) {
    rowHeight = SCHEDULE_ROW_HEIGHT;
  }

  return rowHeight + SCHEDULE_ROW_HEIGHT_MARGIN;
};

export const calculateScheduleTotal = (schedules: ScheduleModel[], cocInSchedule: boolean, teamId?: string) =>
  schedules.reduce((acc, schedule: ScheduleModel) => {
    const scheduleTotal = {
      hours: 0,
      pay: 0,
    };

    if (!schedule.is_task && (!teamId || schedule.team_id === teamId)) {
      scheduleTotal.hours = parseFloat(schedule.total);
      scheduleTotal.pay = parseFloat(cocInSchedule ? schedule.coc : schedule.salary);
    }

    return totalAccumulator(acc, scheduleTotal);
  }, defaultTotal);
