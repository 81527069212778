import { AppState } from '../../index';
import { weatherAdapter, WeatherState } from './weather-state';
import { createSelector } from 'reselect';
import { WeatherForecast, WidgetForecastModel } from './weather.model';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { dayList, periodDateRangeFilter } from '../../../shared/date.helper';
import { Dictionary } from '@ngrx/entity';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { convertArrayToObject } from '../../shared/entity.helper';
import { getAccount } from '../../account/account.service';
import { AccountModel } from '../../account/account.model';
import { getActiveDepartmentsForPermission } from '../department/department.service';
import { DepartmentModel } from '../department/department.model';
import groupBy from 'lodash-es/groupBy';
import { getLocationEntities, getLocations } from '../location/location.service';
import { LocationModel } from '../location/location.model';

export const getWeatherState = (appState: AppState): WeatherState => appState.orm.weather;

export const { selectAll, selectEntities, selectIds, selectTotal } = weatherAdapter.getSelectors(getWeatherState);

export const getWeatherForPeriod = createSelector(
  selectAll,
  getSelectedSchedulePeriod,
  getAccount,
  (weatherForecasts: WeatherForecast[], period: ScheduleFilterPeriod, account: AccountModel) => weatherForecasts.map((forecast) => {
      const filteredTimeBlocks =
        period.periodType === 'day'
          ? forecast.TimeBlocks.filter(periodDateRangeFilter(period.range.start, period.range.end, true))
          : forecast.TimeBlocks;
      return {
        ...forecast,
        TimeBlocks: filteredTimeBlocks,
      };
    })
);

export const getWeatherEntitiesForPeriod = createSelector(
  getWeatherForPeriod,
  (weatherForecasts: WeatherForecast[]): Dictionary<WeatherForecast> => convertArrayToObject('id', weatherForecasts)
);

export const getWeatherEntitiesForWidget = (minDate: Date, maxDate: Date) =>
  createSelector(
    selectAll,
    (weatherForecasts: WeatherForecast[]): Dictionary<WeatherForecast[]> => {
      const forecasts = weatherForecasts
        .map((forecast) => {
          const filteredTimeBlocks = forecast.TimeBlocks.filter(periodDateRangeFilter(minDate, maxDate, true));

          return {
            ...forecast,
            TimeBlocks: filteredTimeBlocks,
          };
        })
        .filter(periodDateRangeFilter(minDate, maxDate, true));

      return groupBy(forecasts, 'department_id');
    }
  );

export const getWeatherForWidget = (minDate: Date, maxDate: Date) =>
  createSelector(
    getWeatherEntitiesForWidget(minDate, maxDate),
    getActiveDepartmentsForPermission({
      permissions: ['View all rosters', 'View own roster'],
      userId: 'me',
    }),
    getLocationEntities,
    (
      weatherForecasts: Dictionary<WeatherForecast[]>,
      departments: DepartmentModel[],
      locations: Dictionary<LocationModel>
    ): WidgetForecastModel[] => departments
        .map((department: DepartmentModel) => ({
            id: department.id,
            location_name: locations[department.location_id].name,
            department_name: department.name,
            forecast: weatherForecasts[department.id] ?? [],
          }))
        .filter((widgetForecast: WidgetForecastModel) => widgetForecast.forecast.length)
  );

export const getWeatherForDepartmentPeriod = (departmentId: string) =>
  createSelector(
    getWeatherEntitiesForPeriod,
    getSelectedSchedulePeriod,
    (weatherForecasts: Dictionary<WeatherForecast>, period): WeatherForecast[] => {
      const forecasts = [];
      const days = dayList(period.minDate, period.maxDate);

      days.forEach((day) => {
        const forecast = weatherForecasts[departmentId + ':' + day];
        if (forecast) {
          forecasts.push(forecast);
        } else {
          forecasts.push({ weather_type: 'na' });
        }
      });
      return forecasts;
    }
  );
