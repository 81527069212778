import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { RequiredShiftFulfillmentApi } from './required-shift-fulfillment-api.service';
import { RequiredShiftFulfillmentAction } from './required-shift-fulfillment.action';
import { RequiredShiftsFulfillmentRequest } from './required-shift-fulfillment.model';

@Injectable()
export class RequiredShiftFulfillmentService {
  public constructor(
    private store: Store<AppState>,
    private api: RequiredShiftFulfillmentApi,
  ) {}

  /**
   * Determine the period to update on a save call
   * @param date
   * @returns {Observable<any>}
   */
  private getSavePeriod(): Observable<{ minDate: string; maxDate: string }> {
    return this.store.select(getSelectedSchedulePeriod).pipe(
      first(),
      map((filterPeriod: ScheduleFilterPeriod) => ({
        minDate: filterPeriod.minDate,
        maxDate: filterPeriod.maxDate,
      })),
    );
  }

  public getRequiredShiftFulfillment(
    requestData: RequiredShiftsFulfillmentRequest,
    update = false,
    updateStore = true,
  ) {
    const check: PermissionCheck = {
      permissions: ['View required shifts'],
      userId: 'me',
      departments: 'any',
    };

    return this.store.select(getPermissionState).pipe(
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => {
        if (hasPerm) {
          return this._fetch(requestData, update, updateStore);
        }

        return observableOf(undefined);
      }),
      first(),
    );
  }

  private _fetch(requestData: RequiredShiftsFulfillmentRequest, update = false, updateStore = true) {
    const timestamp = new Date().getTime();
    const action = update ? RequiredShiftFulfillmentAction.update(timestamp) : RequiredShiftFulfillmentAction.load();

    return this.getSavePeriod().pipe(
      switchMap((period) => {
        const data = {
          ...requestData,
        };

        if (!requestData.minDate || !requestData.maxDate) {
          data.minDate = period.minDate;
          data.maxDate = period.maxDate;
        }

        return this.api.getRequiredShiftFulfillment(data, action).pipe(
          map((response) => {
            if (updateStore) {
              const successAction = update
                ? RequiredShiftFulfillmentAction.updateSuccess(response, timestamp)
                : RequiredShiftFulfillmentAction.loadSuccess(response);
              this.store.dispatch(successAction);
            }
            return response;
          }),
          catchError((response) => {
            const failAction = update
              ? RequiredShiftFulfillmentAction.updateFailed(response)
              : RequiredShiftFulfillmentAction.loadFailed(response);
            this.store.dispatch(failAction);
            return observableOf(undefined);
          }),
        );
      }),
    );
  }
}
