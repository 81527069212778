import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RequiredShiftFulfillmentModel } from './required-shift-fulfillment.model';

export interface RequiredShiftFulfillmentState extends EntityState<RequiredShiftFulfillmentModel> {
  isLoading?: boolean;
  error?: any;
  timestamp: number;
}

export const requiredShiftFulfillmentAdapter: EntityAdapter<RequiredShiftFulfillmentModel> = createEntityAdapter<
  RequiredShiftFulfillmentModel
>({
  selectId: (model) => model.occurrence_id,
});

export const initialState: RequiredShiftFulfillmentState = requiredShiftFulfillmentAdapter.getInitialState({
  isLoading: false,
  error: null,
  timestamp: null,
});
